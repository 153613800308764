
  // import NotesService from "@/services/notes";
  import "vue-simple-suggest/dist/styles.css"; // Optional CSS
  import GlobalServices from "@/services/global";
  import audioComponent from "@/components/_universal/notes-universal-audio.vue";
  import noteComponent from "@/components/_universal/notes-universal-notes.vue";
  import UtilityString from "@/utilities/strings";
  import hash from "object-hash";
  import MODULE from "@/constants/modules";
  const utility = new UtilityString();
  // const api = NotesService.getInstance();
  let global = new GlobalServices();

  //Default line item state
  const newLineItem = {
    id: 0,
    summary: "",
    notes: "",
  };

  export default {
    name: "UniversalNotes",
    props: {
      dataObj: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        noteId: 0,
        selectedItem: {},
        newNote: newLineItem,
        itemList: [],
        showErrorWarning: false,
        lookupList: [],
        timerReload: {},
      };
    },
    components: {
      audioComponent,
      noteComponent,
    },

    methods: {
      async loadDataList() {
        this.noteId = this.dataObj.data.id || this.dataObj.data.intProjectID;

        if (this.noteId > 0) {
          const payload = {
            id: this.dataObj.data.id || this.dataObj.data.intProjectID,
            parentActionId: this.dataObj.param.parentActionId,
            actionId: this.dataObj.param.actionId,
          };
          this.itemList = await global.getTabTableleDataByActionId(payload);
        }
      },

      async addNotes() {
        let newNoteObj = {
          id: this.noteId,
          typeId: this.dataObj.data.typeId,
          parentId: this.dataObj.param.id,
          actionId: this.dataObj.param.actionId,
          parentActionId: this.dataObj.param.parentActionId,
          summary: this.newNote.summary,
          notes: this.newNote.notes,
          hash: null,
        } as any;

        switch (parseInt(this.dataObj.data.typeId)) {
          case MODULE.NOTES.SALES:
            newNoteObj.quoteId = parseInt(this.$route.params.id);
            break;
          case MODULE.NOTES.PROJECT:
            newNoteObj.projectId = parseInt(this.$route.params.id);
            break;
          case MODULE.NOTES.CLIENT:
            newNoteObj.clientId = parseInt(this.$route.params.id);
            break;
          case MODULE.NOTES.INVOICE:
            newNoteObj.invoiceId = parseInt(this.$route.params.id);
            break;
          case MODULE.NOTES.PURCHASE_ORDER:
            newNoteObj.purchaseId = parseInt(this.$route.params.id);
            break;
          case MODULE.NOTES.TEMPLATES:
            newNoteObj.templateId = parseInt(this.$route.params.id);
            newNoteObj.IntTemplateId = parseInt(this.$route.params.id);
            break;
          case MODULE.NOTES.INVENTORY:
            newNoteObj.inventoryId = parseInt(this.$route.params.id);
            newNoteObj.IntInventoryId = parseInt(this.$route.params.id);
            break;
          default:
            break;
        }

        newNoteObj = utility.trimObjectPropertySpace(newNoteObj);

        //Generate a Hash from the data to be submitted
        newNoteObj.hash = hash(newNoteObj);

        this.itemList.unshift({
          id: 0,
          name: this.newNote.summary,
          description: this.newNote.notes,
        });

        let result = await global.createNewActivity(newNoteObj.actionId, newNoteObj);

        if (result) {
          //Reset text
          this.newNote.summary = "";
          this.newNote.notes = "";
          newNoteObj = null;
        }
      },
      async setTimerReload() {
        this.timerReload = setInterval(async () => {
          await this.loadDataList();
        }, 2000);
      },
      async disableReload(isEditing) {
        if (!isEditing) {
          this.loadDataList();

          this.setTimerReload();
        }

        if (isEditing && this.timerReload) {
          clearTimeout(this.timerReload);
        }
      },
    },
    mounted() {
      this.setTimerReload();
    },
    async created() {
      await this.loadDataList();
    },
    beforeDestroy() {
      if (this.timerReload) clearTimeout(this.timerReload);
    },
  };
