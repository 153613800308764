
  import ClientFilters from "@/components/infoFilters/clientFilter.vue";
  import InvoiceFilter from "@/components/infoFilters/invoiceFilter.vue";
  import QuoteFilters from "@/components/infoFilters/quoteFilter.vue";
  import templateFilters from "@/components/infoFilters/templateFilter.vue";
  import PurchaseOrderFilters from "@/components/infoFilters/purchaseOrderFilter.vue";
  import WorkOrderFilters from "@/components/infoFilters/workOrderFilter.vue";
  import SupplierFilters from "@/components/infoFilters/supplierFilter.vue";
  import infoData from "@/components/_universal/info-stats.vue";
  import infoMetaData from "@/components/_universal/info-stats-meta.vue";
  // import ClientSubDetails from "@/components/infoFilters/clientSubDetails.vue";
  import {IInfo} from "@/interfaces/IUXInfo";
  import SearchService from "@/services/search";
  const searchService = new SearchService();
  export default {
    props: {
      dataObj: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        stats: null,
        statTimer: null,
      };
    },
    computed: {
      infoSection() {
        return this.stats.data.filter((data) => !data.isHighlightSection);
      },
      infoHighLightSection() {
        return this.stats.data.filter((data) => data.isHighlightSection);
      },
    },
    methods: {
      async getStats() {
        let info = new Object() as IInfo;
        //Not implemented for now
        info.isEnabled = false;
        let filter = {
          actionId: this.dataObj.actionId,
          id: this.dataObj.data.id,
        };
        let results = await searchService.getResults("global/details/info", filter);
        info.data = results.resources;

        info.isDetailsView = true;
        this.stats = info;
      },
    },
    components: {
      ClientFilters,
      QuoteFilters,
      templateFilters,
      PurchaseOrderFilters,
      WorkOrderFilters,
      SupplierFilters,
      // ClientSubDetails,
      InvoiceFilter,
      // BasicStat: () => import('@/components/infoFilters/sub/basic-stat.vue'),
      // CornerStat: () => import('@/components/infoFilters/sub/corner-stat.vue'),
      infoData,
      infoMetaData,
    },
    mounted() {
      // this.getStats();
      // this.statTimer = setInterval(async () => {
      //   await this.getStats();
      // }, 5000);
    },
    beforeDestroy() {
      if (this.statTimer) clearTimeout(this.statTimer);
    },
  };
