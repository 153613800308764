import { render, staticRenderFns } from "./project-tab-services-materials.vue?vue&type=template&id=fedaaef4&scoped=true"
import script from "./project-tab-services-materials.vue?vue&type=script&lang=ts"
export * from "./project-tab-services-materials.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fedaaef4",
  null
  
)

export default component.exports