
  import Vue from "vue";
  import {VueAccordion, VueAccordionItem} from "vue-accordion-styless";
  import UXProjectsService from "../../services/ux/uxprojects";
  import DashboardService from "@/services/dashboard";
  import ProjectServices from "@/services/projects";
  import EVENTS from "@/constants/events";

  export default Vue.extend({
    name: "TableAction",
    data() {
      return {
        EVENT_ACTION: {},
        dispatchDate: new Date().toISOString().slice(0, 10),
        isDispatching: false,
        isExportingStarted: false,
        downloadLink: "",
        isExporting: false,
        exportFileResponse: "",
        dispatchResponseVisible: false,
        displayDispatchResponse: "",
        selectedTechnician: null,
        selectedAction: null,
        actionList: [
          {id: 0, displayName: "Dispatch Technician"},
          {id: 1, displayName: "Export to Excel"},
        ],
        selectedActionId: 0,
        technicianList: [],
        isClose: true,
      };
    },
    methods: {
      toggle() {
        this.isClose = !this.isClose;
      },
      async onDownloadFile(event) {
        if (this.selectedAction === "Export to Excel") {
          this.isExportingStarted = true;

          let api = new DashboardService();

          this.downloadLink = "";
          let filterParam = this.$store.getters.getFilter;

          // re-use getProjects api function and just append export = excel so it will trigger the download
          this.downloadLink = await api.getProjects({...filterParam, export: "excel"});

          //ONLY TRACK EVENTS IN PRODUCTION
          // if (process.env.VUE_APP_ENVIRONMENT === "production") {
          //   window["analytics"].track(this.EVENT_ACTION.DASHBOARD.Action.ExportExcel, this.downloadLink);
          // }

          this.triggerDownload(this.downloadLink.resources);

          this.exportFileResponse = "File succesfully downloaded.";

          setTimeout(() => {
            this.isExportingStarted = false;
          }, 5000);
        }
      },
      triggerDownload(url) {
        window.open(url, "_blank");
      },
      onTechnicianSelected(event) {},
      async dispatchTechnicianAction() {
        let dashboardService = new DashboardService();

        if (this.selectedAction === "Dispatch Technician") {
          let technicianId = this.selectedTechnician.id;
          let projectsList = this.$store.getters.getSelectedProjects;

          window["analytics"].track(this.EVENT_ACTION.DASHBOARD.Action.QuickActionDispatchTechnician, projectsList);

          this.displayDispatchResponse = await dashboardService.dispatchTechnicians(technicianId, this.dispatchDate, projectsList, "");

          this.dispatchResponseVisible = true;

          //Reset Selected Projects
          this.$store.dispatch("resetSelectedProjectList");
          projectsList = null;

          setTimeout(() => {
            this.dispatchResponseVisible = false;
          }, 5000);
        }
      },
      onActionSelected(event) {
        this.selectedAction = event.displayName;

        if (this.selectedAction === "Dispatch Technician") {
          this.loadTechnicianDropdown();
          this.isDispatching = true;
          this.dispatchDate = new Date().toISOString().slice(0, 10);
        } else {
          this.isDispatching = false;
        }

        if (this.selectedAction === "Export to Excel") {
          this.isExporting = true;
        } else {
          this.isExporting = false;
        }
      },
      async loadTechnicianDropdown() {
        this.technicianList = await this.$store.getters.getTechnicianDropDown;
      },
    },
    computed: {},

    mounted() {
      this.$store.subscribe((mutation, state) => {
        //UX Trigger toggle for More Options section if has selected project values..

        if (mutation.type === "updateSelectedProjectsList") {
          //Get reference to More Options "Quick Action" link to simulate click event to hide/show section.
          let toggleElement = this.$refs.toggleMoreOptions;

          if (this.isClose) {
            if (toggleElement) {
              toggleElement.click();
            }
          } else if (state.Dashboard.selectedProjects.length === 0) {
            if (!this.isClose) {
              if (toggleElement) {
                toggleElement.click();
              }
            }
          }
        }

        //Monitor if the technician list change to load the dropdown.
        if (mutation.type === "setTechnicianDropdown") {
          this.loadTechnicianDropdown();
        }
      });
    },
    created() {
      this.EVENT_ACTION = EVENTS;
    },

    components: {VueAccordion, VueAccordionItem},
  });
