var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Website")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Website),expression:"currentDataObj.details.Website"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-others-website","type":"text"},domProps:{"value":(_vm.currentDataObj.details.Website)},on:{"keyup":function($event){return _vm.updateField(
            'Website', //PropertyName
            _vm.currentDataObj.details.Website, //Property Value
            'Website', //Display Name
            _vm.currentDataObj.details.Website //Display Value
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "Website", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("LinkedIn")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.LinkedIn),expression:"currentDataObj.details.LinkedIn"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-linkedin"},domProps:{"value":(_vm.currentDataObj.details.LinkedIn)},on:{"keyup":function($event){return _vm.updateField(
          'LinkedIn', //PropertyName
          _vm.currentDataObj.details.LinkedIn, //Property Value
          'LinkedIn', //Display Name
          _vm.currentDataObj.details.LinkedIn //Display Value
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "LinkedIn", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Facebook")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.FacebookPage),expression:"currentDataObj.details.FacebookPage"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-facebook"},domProps:{"value":(_vm.currentDataObj.details.FacebookPage)},on:{"keyup":function($event){return _vm.updateField(
          'FacebookPage', //PropertyName
          _vm.currentDataObj.details.FacebookPage, //Property Value
          'Facebook', //Display Name
          _vm.currentDataObj.details.FacebookPage //Display Value //Display Name
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "FacebookPage", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Twitter")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Twitter),expression:"currentDataObj.details.Twitter"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-twitter"},domProps:{"value":(_vm.currentDataObj.details.Twitter)},on:{"keyup":function($event){return _vm.updateField(
          'Twitter', //PropertyName
          _vm.currentDataObj.details.Twitter, //Property Value
          'Twitter', //Display Name
          _vm.currentDataObj.details.Twitter //Display Value
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "Twitter", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Tags")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.TagName),expression:"currentDataObj.details.TagName"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-tags"},domProps:{"value":(_vm.currentDataObj.details.TagName)},on:{"keyup":function($event){return _vm.updateField(
          'TagName', //PropertyName
          _vm.currentDataObj.details.TagName, //Property Value
          'Tags', //Display Name
          _vm.currentDataObj.details.TagName //Display Value
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "TagName", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Territory")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Territory),expression:"currentDataObj.details.Territory"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-territory"},domProps:{"value":(_vm.currentDataObj.details.Territory)},on:{"keyup":function($event){return _vm.updateField(
          'Territory', //PropertyName
          _vm.currentDataObj.details.Territory, //Property Value
          'Territory', //Display Name
          _vm.currentDataObj.details.Territory //Display Value
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "Territory", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('global-settings-modal',{attrs:{"label":"Preferred Language","actionId":_vm.MODULE.CLIENTS.UX.PreferredLanguage,"id":"slidepanel-client-details-tabs-others-global-setting-preffered-language"}}),_c('v-select',{attrs:{"options":_vm.uxPreferredLanguage,"label":"displayName","id":"slidepanel-client-details-tabs-others-preferred-language","clearable":false,"reduce":(language) => language.id},on:{"input":function($event){return _vm.updateField(
            'IntPreferredLanguageID', //PropertyName
            _vm.currentDataObj.details.IntPreferredLanguageID, //Property Value
            'Preferred Language', //Display Name
            _vm.languageDisplayValue, //Display Value
            'intPreferredLanguageID' //Data object Property Name
          )}},model:{value:(_vm.currentDataObj.details.IntPreferredLanguageID),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "IntPreferredLanguageID", $$v)},expression:"currentDataObj.details.IntPreferredLanguageID"}})],1),_c('div',{staticClass:"col-md-3 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Custom Field 1")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.CustomField1),expression:"currentDataObj.details.CustomField1"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-custom-field-1"},domProps:{"value":(_vm.currentDataObj.details.CustomField1)},on:{"keyup":function($event){return _vm.updateField(
          'CustomField1', //PropertyName
          _vm.currentDataObj.details.CustomField1, //Property Value
          'Custom Field 1' //Display Name
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "CustomField1", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Custom Field 2")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.CustomField2),expression:"currentDataObj.details.CustomField2"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-custom-field-2"},domProps:{"value":(_vm.currentDataObj.details.CustomField2)},on:{"keyup":function($event){return _vm.updateField(
          'CustomField2', //PropertyName
          _vm.currentDataObj.details.CustomField2, //Property Value
          'Custom Field 2' //Display Name
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "CustomField2", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Custom Field 3")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.CustomField3),expression:"currentDataObj.details.CustomField3"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-custom-field-2"},domProps:{"value":(_vm.currentDataObj.details.CustomField3)},on:{"keyup":function($event){return _vm.updateField(
          'CustomField3', //PropertyName
          _vm.currentDataObj.details.CustomField3, //Property Value
          'Custom Field 3' //Display Name
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "CustomField3", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Custom Field 4")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.CustomField4),expression:"currentDataObj.details.CustomField4"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-custom-field-4"},domProps:{"value":(_vm.currentDataObj.details.CustomField4)},on:{"keyup":function($event){return _vm.updateField(
          'CustomField4', //PropertyName
          _vm.currentDataObj.details.CustomField4, //Property Value
          'Custom Field 4' //Display Name
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "CustomField4", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Custom Field 5")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.CustomField5),expression:"currentDataObj.details.CustomField5"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-custom-field-5"},domProps:{"value":(_vm.currentDataObj.details.CustomField5)},on:{"keyup":function($event){return _vm.updateField(
          'CustomField5', //PropertyName
          _vm.currentDataObj.details.CustomField5, //Property Value
          'Custom Field 5' //Display Name
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "CustomField5", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Custom Field 6")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.CustomField6),expression:"currentDataObj.details.CustomField6"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-custom-field-6"},domProps:{"value":(_vm.currentDataObj.details.CustomField6)},on:{"keyup":function($event){return _vm.updateField(
          'CustomField6', //PropertyName
          _vm.currentDataObj.details.CustomField6, //Property Value
          'Custom Field 6' //Display Name
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "CustomField6", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Custom Field 7")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.CustomField7),expression:"currentDataObj.details.CustomField7"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-custom-field-7"},domProps:{"value":(_vm.currentDataObj.details.CustomField7)},on:{"keyup":function($event){return _vm.updateField(
          'CustomField7', //PropertyName
          _vm.currentDataObj.details.CustomField7, //Property Value
          'Custom Field 7' //Display Name
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "CustomField7", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Custom Field 8")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.CustomField8),expression:"currentDataObj.details.CustomField8"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-others-custom-field-8"},domProps:{"value":(_vm.currentDataObj.details.CustomField8)},on:{"keyup":function($event){return _vm.updateField(
          'CustomField8', //PropertyName
          _vm.currentDataObj.details.CustomField8, //Property Value
          'Custom Field 8' //Display Name
        )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "CustomField8", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('b-form-checkbox',{attrs:{"id":"chkIsSample","name":"chkIsSample"},on:{"change":function($event){return _vm.checkboxChanged(
          'IsSample', //PropertyName
          _vm.currentDataObj.details.IsSample, //Property Value
          'Is Sample Data' //Display Name
        )}},model:{value:(_vm.currentDataObj.details.IsSample),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "IsSample", $$v)},expression:"currentDataObj.details.IsSample"}},[_vm._v(" Sample Data ")]),_c('b-form-checkbox',{attrs:{"id":"chkisInActive","name":"chkisInActive"},on:{"change":function($event){return _vm.checkboxChanged(
          'isInActive', //PropertyName
          _vm.currentDataObj.details.isInActive, //Property Value
          'Disable Account' //Display Name
        )}},model:{value:(_vm.currentDataObj.details.isInActive),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "isInActive", $$v)},expression:"currentDataObj.details.isInActive"}},[_vm._v(" Disable Account ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }