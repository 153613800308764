import axios from "axios";
import AuthService from "@/auth/authService";
import UtilityString from "@/utilities/strings";
import hash from "object-hash";
let auth = AuthService.getInstance();
let apiHeaderObj;

class EmailService {
    utility = new UtilityString();
  constructor() {
    apiHeaderObj = {
      headers: {
        "x-snapsuite-key": "da16f3b6984d4da795342f7ae74ee8d9",
      },
      baseURL: 'https://api.getsnapsuite.com/'
    };
  }

  async sendEmail(params) {
    let payload = {
        ...params,
        ...auth.getAuthObject()
    }
    payload.id = this.utility.generateGUID();
    payload.hash = hash(payload);
    payload.correlationId = this.utility.generateGUID();
    let dataResults = await axios.post("email/ingest", payload, apiHeaderObj);
    return dataResults.data;
  }
}

export default EmailService;