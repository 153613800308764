
  // import QuoteServices from "@/services/quotes";
  import "vue-simple-suggest/dist/styles.css"; // Optional CSS
  import editMixin from "@/mixin/edit-mixin";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import UtilityString from "@/utilities/strings";
  import UXInventoryService from "@/services/ux/uxinventory";
  const utility = new UtilityString();
  const global = new GlobalServices();
  // const api = new QuoteServices();

  export default {
    mixins: [editMixin],
    name: "JobCostingListItems",
    props: {
      items: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      items(value) {
        this.quoteDetailsItems = value;
      },
    },
    data() {
      return {
        editingRow: false,
        defaultInventorySelected: {
          displayName: "Pricing (Optional)",
          id: "0",
        },
        itemDescriptionLength: 5,
        selectedItem: {},
        quoteDetailsItems: [],
        showErrorWarning: false,
        subTotal: 0,
        subLabor: 0,
        inventoryList: [],
        categoryList: [],
        id: 0,
      };
    },

    computed: {
      materialCost() {
        if (this.quoteDetailsItems.length > 0) {
          return this._.sumBy(this.quoteDetailsItems, (item) => item.materialCost);
        }

        return 0;
      },
      totalLabor() {
        if (this.quoteDetailsItems.length > 0) {
          return this._.sumBy(this.quoteDetailsItems, (item) => item.labourTotalHours);
        }

        return 0;
      },
      finalPrice() {
        if (this.quoteDetailsItems.length > 0) {
          return this._.sumBy(this.quoteDetailsItems, (item) => item.totalCost);
        }

        return 0;
      },
    },

    methods: {
      onSelectCategorySuggestion(selectedObj) {
        if (this.selectedItem) {
          if (selectedObj) {
            this.selectedItem.intQuoteTypeID = selectedObj.id;
          }
        }
      },
      async onSelectSuggestion(selectedObj) {
        // if (this.selectedItem) {
        //   if (selectedObj) {
        //     this.selectedItem.description = selectedObj.displayName;
        //     this.selectedItem.unitPrice = selectedObj.unitPrice;
        //     this.selectedItem.materialCost = this._.multiply(this.selectedItem.qty, this.selectedItem.unitPrice);
        //     this.calculateTotalSum();
        //   }
        // }
        if (this.selectedItem) {
          let item = await global.getEditDetails(selectedObj.actionId, selectedObj.id);
          this.selectedItem.title = item.Name;
          this.selectedItem.description =
            item.Name && item.Description ? `${item.Name}\n${item.Description ? item.Description : ""}` : `${selectedObj.displayName}\n${selectedObj.description ? selectedObj.description : ""}`;
          this.selectedItem.qty = item && item.qty && parseFloat(item.qty) !== 0 ? parseFloat(item.qty) : selectedObj.qty && parseFloat(selectedObj.qty) !== 0 ? parseFloat(selectedObj.qty) : 1;
          this.selectedItem.unitPrice = item && item.SalePrice ? item.SalePrice : selectedObj.unitPrice && parseFloat(selectedObj.unitPrice) !== 0 ? parseFloat(selectedObj.unitPrice) : 0;

          this.selectedItem.materialCost = this._.multiply(this.selectedItem.qty, this.selectedItem.unitPrice);
          this.calculateItemTotal();
        }
      },
      async onLoadInventory() {
        let inventoryService = new UXInventoryService();

        this.inventoryList = await inventoryService.getInventoryList("");
      },
      // async onLoadInventory() {
      //   this.inventoryList = await global.getUXDropDown(MODULE.PRICING.ActionId);
      // },
      async saveRow(index) {
        this.selectedItem = null;
        this.editingRow = false;
        this.calculateTotalSum();
        // temporarily saved it on array
        // await api.updateData(this.quoteDetailsItems[index]);

        // await this.reloadItemList();
      },
      // async reloadItemList() {
      //   this.quoteDetailsItems = await api.getQuoteDetails(this.dataObj.data.id);
      // },
      editRow(index) {
        this.selectedItem = this.quoteDetailsItems[index];
        this.editingRow = true;
      },
      calculateItemTotal(index) {
        // if (this.selectedItem) {
        //   let qty = this.selectedItem.qty;
        //   let unitPrice = this.selectedItem.unitPrice;
        //   const hour = this.selectedItem.labourUnitPerHour;
        //   const minute = this.selectedItem.labourUnitPerMinute;
        //   this.selectedItem.materialCost = this._.multiply(qty, unitPrice);
        //   this.selectedItem.labourTotalHours = this._.multiply(qty, `${hour}.${minute}`);
        //   this.selectedItem.totalCost = this._.multiply(this.selectedItem.materialCost, this.selectedItem.labourTotalHours);
        // }
      },
      calculateTotalSum() {
        this.subTotal = this._.sumBy(this.quoteDetailsItems, (item) => item.materialCost);
        this.$emit("labor-placeholder", {
          materialCost: this.materialCost,
          totalLabor: this.totalLabor,
          finalPrice: this.finalPrice,
          estLaborHours: this.totalLabor,
          items: this.quoteDetailsItems,
        });
        let formattedValue = this.$options.filters.currency(this.subTotal);

        //Broadcast to any component that care, that the total was changes...
        this.$root.$emit(this.EVENT_ACTION.INFO.UpdateTotal, formattedValue);
      },
      showHideLabel(index) {
        //If we're in disable edit mode, hide options
        if (this.isDisabled) {
          return false;
        } else {
          if (this.isEditingCurrentRow(index)) return false;
          else return true;
        }
      },
      showHideTextBox(index) {
        if (this.isEditingCurrentRow(index)) return true;
        else return false;
      },
      isEditingCurrentRow(index) {
        if (this.selectedItem === this.quoteDetailsItems[index]) return true;
        else return false;
      },
      async deleteItem(index) {
        // await api.deleteItem(this.quoteDetailsItems[index]);
        this.quoteDetailsItems.splice(index, 1);
        this.selectedItem = null;
        this.calculateTotalSum();
      },
      async cancelRow(index) {
        if (this.isEditingLastRow()) {
          if (!this.isLastItemRowValid()) this.deleteItem(index);
        }
        this.showErrorWarning = false;
        this.selectedItem = null;
        this.editingRow = false;
      },
      async addNewItem() {
        const newQuoteLineItem = {
          id: utility.generateGUID(),
          qid: this.dataObj.data.id,
          quoteDetailId: 0,
          description: "",
          intQuoteTypeID: 1,
          qty: 1,
          unitPrice: 0,
          labourUnitPerHour: 0,
          labourUnitPerMinute: 0,
          materialCost: 0,
          labor: 0,
          totalCost: 0,
        };

        let shouldAddNewItem = false;

        //Evaluate the last row before adding new item row
        if (this.isLastItemRowValid()) {
          shouldAddNewItem = true;
        } else if (this.quoteDetailsItems.length === 0) {
          shouldAddNewItem = true;
        }

        if (shouldAddNewItem) {
          this.quoteDetailsItems.push(newQuoteLineItem);
          this.editRow(this.quoteDetailsItems.length - 1);
        }
      },
      isLastItemRowValid() {
        //Get the last Item row and ensure that it has values, before allowing user to add additional rows
        let allowAddingNewRow = false;

        if (this.quoteDetailsItems.length > 0) {
          //If current select item is the last row
          if (this.isEditingLastRow()) {
            //If they are no items in the last row then show error message.
            if (this.selectedItem.description === "" && this.selectedItem.qty === 1 && this.selectedItem.unitPrice === 0) {
              this.showErrorWarning = true;
              setTimeout(() => (this.showErrorWarning = false), 4000);
            } else {
              allowAddingNewRow = true;

              //If the last row is not blank, then save it to databse.
              this.saveRow(this.quoteDetailsItems.length - 1);
            }
          } else {
            allowAddingNewRow = true;
          }
        }

        return allowAddingNewRow;
      },
      isEditingLastRow() {
        if (this.selectedItem == this.quoteDetailsItems[this.quoteDetailsItems.length - 1]) return true;

        return false;
      },
      initializeData() {
        const newQuoteLineItem = {
          qid: this.dataObj.data.id,
          quoteDetailId: 0,
          title: "",
          description: "",
          intQuoteTypeID: 1,
          qty: 1,
          unitPrice: 0,
          labourUnitPerHour: 0,
          labourUnitPerMinute: 0,
          materialCost: 0,
          labourTotalHours: 0,
          totalCost: 0,
        };

        this.quoteDetailsItems.push(newQuoteLineItem);
        this.editRow(this.quoteDetailsItems.length - 1);
      },
      async reloadList() {
        const item = await global.globalCalculations(MODULE.QUOTATION.ActionId, this.dataObj.data.id, this.dataObj.details.GuidID);
        // this.quoteDetailsItems = item && item.jobCostingDetails ? item.jobCostingDetails : [];
      },
    },
    mounted() {
      this.onLoadInventory();
      this.reloadList();
      this.$root.$on("update-job-cost-list", this.reloadList);
    },

    beforeDestroy() {
      this.$root.$off("update-job-cost-list");
    },
  };
