import Vue from "vue";
import VueRouter from "vue-router";
import AuthLoginCallBack from "@/auth/callback.vue";
import Search from "@/components/autosuggest/Search.vue";
import {authGuard} from "@/auth/authGuard";
import {searchGuard} from "@/auth/search";
import App from "@/App.vue";
import store from "@/store";
import { SalesDescription } from "@/components";
const Dashboard = () => import("@/views/Dashboard.vue");
const QuoteDetails_v2 = () => import("@/views/sales/details.vue");

const PurchaseOrderDetails = () => import("@/views/purchase-order/PurchaseOrderDetails.vue");
const InvoiceDetails = () => import("@/views/invoice/InvoiceDetails.vue");

const QuoteDescription = () => import("@/components/sales/tab-description.vue");
const QuoteTerms = () => import("@/components/sales/tab-terms.vue");
const Notes = () => import("@/components/_universal/notes.vue");
const infoFilterList = () => import("@/components/infoFilters/infoFilterList.vue");
const ListTable = () => import("@/components/listTable/listTable.vue");
const ClientList = () => import("@/views/clients/list.vue");
const ClientEquipmentList = () => import("@/components/clients/client-equipment-list-new-tab.vue");
const ContactList = () => import("@/views/contacts/list.vue");
const AdvanceFilter = () => import("@/views/advance-filter/advance-filter.vue");
const InvoiceList = () => import("@/views/invoice/list.vue");

const AccountsReceivableList = () => import("@/views/accounts-receivable/list.vue");
const ListPayments = () => import("@/views/list-payments/list.vue");
const QuoteList = () => import("@/views//sales/quote/list.vue");
const SidePanelBody = () => import("@/layouts/side-panel-body.vue");

const Settings = () => import("@/views/settings/settings.vue");
const Planner = () => import("@/views/planner/planner.vue");
const Activity = () => import("@/components/activity/activity-history.vue");
const Map = () => import("@/views/map/map.vue");
const SearchPage = () => import("@/views/search/search-page.vue");
const SearchDetails = () => import("@/views/search/details.vue");

const ExtendedList = () => import("@/views/list/extended.vue");

const NoAccess = () => import("@/views/no-access/index.vue");

const PDFPreview = () => import("@/views/pdf/preview.vue");

const ImagePreview = () => import("@/views/image-preview/preview.vue");

//Projects
const ProjectQuickEdit = () => import("@/components/projects/projectedit.vue");

const ProjectDetailsTabPermits = () => import("@/components/projects/project-tab-permits.vue");
const ProjectDetailsTabPurchaseOrder = () => import("@/components/projects/project-tab-purchase-order.vue");
const ProjectDetailsTabInvoices = () => import("@/components/projects/project-tab-invoices.vue");
const ProjectDetailsTabEquipments = () => import("@/components/projects/project-tab-equipment.vue");
const ProjectDetailsTabEServicesMaterials = () => import("@/components/projects/project-tab-services-materials.vue");
const ProjectDetailsTabEDocumentsPhotos = () => import("@/components/projects/project-tab-documents-photos.vue");
const ProjectDetailsTabTasks = () => import("@/components/projects/project-tab-tasks.vue");
const ProjectDetailsTabTimeTracking = () => import("@/components/projects/project-tab-time-tracking.vue");
const ProjectDetailsTabAssignStaff = () => import("@/components/projects/project-tab-assign-staff.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/callback",
    name: "callback",
    component: AuthLoginCallBack,
    beforeEnter: authGuard,
  },
  {
    path: "/",
    name: "index",
    components: {
      body: Dashboard,
    },
    beforeEnter: authGuard,
    // meta: { gtm: "Dashboard" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    components: {
      body: Dashboard,
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Dashboard" },
  },
  {
    path: "/map",
    name: "map",
    components: {
      body: Map,
    },
    beforeEnter: authGuard,
    // meta: { gtm: "Map" },
  },
  {
    path: "/search",
    name: "SearchPage",
    components: {
      body: SearchPage,
      sidepanel: SearchDetails,
    },
    beforeEnter: searchGuard,
    //meta: { gtm: "SearchPage" },
    children: [
      {
        path: "details/:id",
        name: "search-details",
        beforeEnter: authGuard,
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    components: {
      body: Settings,
    },
    beforeEnter: authGuard,
    // meta: { gtm: "Settings" },
  },
  {
    path: "/planner",
    name: "planner",
    components: {
      body: Planner,
    },
    beforeEnter: authGuard,
    // meta: { gtm: "Planner" },
  },
  {
    path: "/projects",
    name: "projects",
    components: {
      body: Dashboard,
      sidepanel: () => import("@/views/projects/details.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Projects" },
    children: [
      {
        path: "details/:id",
        name: "projects-details",
        beforeEnter: authGuard,
        // meta: { gtm: "Project Details" },
      },
      {
        path: "details/permits",
        name: "project-details-tab-permits",
        component: ProjectDetailsTabPermits,
        beforeEnter: authGuard,
        // meta: { gtm: "Project Permits" },
      },
      {
        path: "details/purchase-order",
        name: "project-details-tab-purchase-order",
        component: ProjectDetailsTabPurchaseOrder,
        beforeEnter: authGuard,
        // meta: { gtm: "Purchase Order Details Tab" },
      },
      {
        path: "details/invoices",
        name: "project-details-tab-invoices",
        component: ProjectDetailsTabInvoices,
        beforeEnter: authGuard,
        // meta: { gtm: "Project Order Details Tab" },
      },
      {
        path: "details/equipment",
        name: "project-details-tab-equipment",
        component: ProjectDetailsTabEquipments,
        beforeEnter: authGuard,
        //meta: { gtm: "Project Equipment Tab" },
      },
      {
        path: "details/services-materials",
        name: "project-details-tab-services-materials",
        component: ProjectDetailsTabEServicesMaterials,
        beforeEnter: authGuard,
        // meta: { gtm: "Project Services/Materials Tab" },
      },
      {
        path: "details/documents-photos",
        name: "project-details-tab-documents-photos",
        component: ProjectDetailsTabEDocumentsPhotos,
        beforeEnter: authGuard,
        //meta: { gtm: "Project Documents/Photos Tab" },
      },
      {
        path: "details/tasks",
        name: "project-details-tab-tasks",
        component: ProjectDetailsTabTasks,
        beforeEnter: authGuard,
        //meta: { gtm: "Project Tasks Tab" },
      },
      {
        path: "details/time-tracking",
        name: "project-details-tab-time-tracking",
        component: ProjectDetailsTabTimeTracking,
        beforeEnter: authGuard,
        //meta: { gtm: "Project Time Tracking Tab" },
      },
      {
        path: "details/assign-staff",
        name: "project-details-tab-assign-staff",
        component: ProjectDetailsTabAssignStaff,
        beforeEnter: authGuard,
        // meta: { gtm: "Project Assign Staff" },
      },
    ],
  },
  {
    path: "/advance-filter",
    name: "advance-filter",
    components: {
      body: AdvanceFilter,
      sidepanel: () => import("@/views/advance-filter/advance-filter.vue"),
    },
  },
  {
    path: "/clients",
    name: "clients",
    components: {
      body: ClientList,
      sidepanel: () => import("@/views/clients/details.vue"),
    },
    beforeEnter: authGuard,
    //  meta: { gtm: "Clients" },
    children: [
      {
        path: "details/:id",
        name: "client-details",
        //  meta: { gtm: "Client Details" },
      },
      {
        path: "details/:id/equipment-list",
        name: "clients-equipment-list",
        component: ClientEquipmentList,
        beforeEnter: authGuard,
        //meta: { gtm: "Project Time Tracking Tab" },
      },
    ],
  },
  {
    path: "/accounts-receivable/list",
    name: "accounts-receivable",
    components: {
      body: AccountsReceivableList,
      // sidepanel: () => import("@/views/clients/details.vue"),
    },
    beforeEnter: authGuard,
    //  meta: { gtm: "Accounts Receiavable" },
  },
  {
    path: "/payments/list/v2",
    name: "paymentsv2",
    components: {
      body: ListPayments,
      // sidepanel: () => import("@/views/clients/details.vue"),
    },
    beforeEnter: authGuard,
    //  meta: { gtm: "Accounts Receiavable" },
  },
  {
    path: "/leads",
    name: "leads",
    components: {
      body: () => import("@/views/leads/list.vue"),
      sidepanel: () => import("@/views/leads/details.vue"),
    },
    beforeEnter: authGuard,
    // meta: { gtm: "Leads" },
    children: [
      {
        path: "details/:id",
        name: "lead-details",
        //  meta: { gtm: "Lead Details" },
      },
    ],
  },
  {
    path: "/prospects",
    name: "Prospect",
    components: {
      body: () => import("@/views/prospects/list.vue"),
      sidepanel: () => import("@/views/prospects/details.vue"),
    },
    beforeEnter: authGuard,
    // meta: { gtm: "Prospects" },
    children: [
      {
        path: "details/:id",
        name: "prospect-details",
        //meta: { gtm: "Prospects Details" },
      },
    ],
  },
  {
    path: "/contacts",
    name: "contacts",
    components: {
      body: ContactList,
      sidepanel: () => import("@/views/contacts/details.vue"),
    },
    beforeEnter: authGuard,
    // meta: { gtm: "Contacts" },
    children: [
      {
        path: "details/:id",
        name: "contact-details",
        //meta: { gtm: "Contact Details" },
      },
    ],
  },
  {
    path: "/sales",
    name: "sales",
    components: {
      body: () => import("@/views/sales/list.vue"),
      sidepanel: () => import("@/views/sales/details.vue"),
    },
    beforeEnter: authGuard,
    // meta: { gtm: "Quotes" },
    children: [
      {
        path: "details/:id",
        name: "quote-details",
        //meta: { gtm: "Quote Details" },
      },
    ],
  },
  {
    path: "/purchase-orders",
    name: "purchase-orders",
    components: {
      body: () => import("@/views/purchase-order/list.vue"),
      sidepanel: () => import("@/views/purchase-order/details.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Purchase Orders" },
    children: [
      {
        path: "details/:id",
        name: "purchase-order-details",
        //meta: { gtm: "Purchase Order Details" },
      },
    ],
  },
  {
    path: "/invoices",
    name: "invoices",
    components: {
      body: () => import("@/views/invoice/list.vue"),
      sidepanel: () => import("@/views/invoice/details.vue"),
    },
    beforeEnter: authGuard,
    // meta: { gtm: "Invoices" },
    children: [
      {
        path: "details/:id",
        name: "invoice-details",
        //meta: { gtm: "Invoice Details" },
      },
    ],
  },
  {
    path: "/suppliers",
    name: "suppliers",
    components: {
      body: () => import("@/views/suppliers/list.vue"),
      sidepanel: () => import("@/views/suppliers/details.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Suppliers" },
    children: [
      {
        path: "details/:id",
        name: "supplier-details",
        //meta: { gtm: "Supplier Details" },
      },
    ],
  },
  {
    path: "/templates",
    name: "templates",
    components: {
      body: () => import("@/views/templates/list.vue"),
      sidepanel: () => import("@/views/templates/details.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Templates" },
    children: [
      {
        path: "details/:id",
        name: "template-details",
        //meta: { gtm: "Template Details" },
      },
    ],
  },
  {
    path: "/templates/documents",
    name: "templates-documents",
    components: {
      body: () => import("@/views/templates/list-documents.vue"),
      sidepanel: () => import("@/views/templates/details.vue"),
    },
    beforeEnter: authGuard,
    children: [
      {
        path: "details/:id",
        name: "template-documents-details",
        //meta: { gtm: "Template Details" },
      },
    ],
  },
  {
    path: "/inventory",
    name: "inventory",
    components: {
      body: () => import("@/views/inventory/list.vue"),
      sidepanel: () => import("@/views/inventory/details.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Inventory" },
    children: [
      {
        path: "details/:id",
        name: "inventory-details",
        //meta: { gtm: "Inventory Details" },
      },
    ],
  },
  {
    path: "/external-applications/list",
    name: "external-applications",
    components: {
      body: () => import("@/views/external-applications/list.vue"),
      // sidepanel: () => import("@/views/inventory/details.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Inventory" },
  },
  {
    path: "/pricing",
    name: "pricing",
    components: {
      body: () => import("@/views/pricing/list.vue"),
      sidepanel: () => import("@/views/pricing/details.vue"),
    },
    beforeEnter: authGuard,
    children: [
      {
        path: "details/:id",
        name: "pricing-details",
      },
    ],
  },
  {
    path: "/pages",
    name: "pages",
    components: {
      body: () => import("@/views/pages/list.vue"),
      sidepanel: () => import("@/views/pages/details.vue"),
    },
    beforeEnter: authGuard,
    children: [
      {
        path: "details/:id",
        name: "pages-details",
      },
    ],
  },
  {
    path: "/pages/new",
    name: "PagesNew",
    component: () => import("@/views/pages/new.vue"),
    beforeEnter: authGuard,
    //meta: { gtm: "Activity" },
  },
  {
    path: "/payments",
    name: "payments",
    components: {
      body: () => import("@/views/payments/list.vue"),
      sidepanel: () => import("@/views/payments/details.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Payments" },
    children: [
      {
        path: "details/:id",
        name: "payment-details",
        // meta: { gtm: "Payment Details" },
      },
    ],
  },
  {
    path: "/employees",
    name: "employees",
    components: {
      body: () => import("@/views/employees/list.vue"),
      sidepanel: () => import("@/views/employees/details.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Payments" },
    children: [
      {
        path: "details/:id",
        name: "employees-details",
        // meta: { gtm: "Payment Details" },
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    components: {
      body: () => import("@/views/admin/list.vue"),
      sidepanel: () => import("@/views/admin/details.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Admin" },
    children: [
      {
        path: "details/:id",
        name: "admin-details",
        //meta: { gtm: "Activity Logs" },
      },
    ],
  },
  {
    path: "/admin/modules",
    name: "admin-modules",
    components: {
      body: () => import("@/views/admin/list-modules.vue"),
      sidepanel: () => import("@/views/admin/details-modules.vue"),
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Admin Modules" },
    children: [
      {
        path: "details/:id",
        name: "admin-details-modules",
        // meta: { gtm: "Activity Modules" },
      },
    ],
  },
  {
    path: "/activities",
    name: "Activity",
    component: Activity,
    beforeEnter: authGuard,
    //meta: { gtm: "Activity" },
  },
  {
    path: "/side-panel-body",
    name: "SidePanelBody",
    component: SidePanelBody,
    beforeEnter: authGuard,
  },

  {
    path: "/quote/list",
    name: "Quotes",
    component: QuoteList,
    beforeEnter: authGuard,
    //meta: { gtm: "Sales Quotes" },
  },
  {
    path: "/tab/details/:parentActionId/:id/:moduleName/:actionId",
    name: "extended-list",
    components: {
      body: ExtendedList,
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Extended List" },
  },
  {
    path: "/no-access",
    name: "no-access",
    components: {
      body: NoAccess,
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Extended List" },
  },
  {
    path: "/pdf/preview",
    name: "pdf-preview",
    components: {
      body: PDFPreview,
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Extended List" },
  },
  {
    path: "/image/preview",
    name: "image-preview",
    components: {
      body: ImagePreview,
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Extended List" },
  },
  {
    path: "/invoice/list",
    name: "Invoices",
    component: InvoiceList,
    beforeEnter: authGuard,
    //meta: { gtm: "Invoices" },
  },

  {
    path: "/notes",
    name: "Notes",
    component: Notes,
    beforeEnter: authGuard,
    //meta: { gtm: "Notes" },
  },
  {
    path: "/list-table",
    name: "ListTable",
    component: ListTable,
    beforeEnter: authGuard,
  },
  {
    path: "/list-filter",
    name: "infoFilterList",
    component: infoFilterList,
    beforeEnter: authGuard,
  },

  {
    path: "/sales/quote/details_v2",
    name: "salesquote_v2",
    component: QuoteDetails_v2,
    beforeEnter: authGuard,
    //meta: { gtm: "Sales Quote Details" },
  },
  {
    path: "/sales/quote/details/terms",
    name: "salesquoteterms",
    components: {
      body: QuoteTerms,
      sidepanel: null,
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Sales Quote Terms" },
  },
  {
    path: "/sales/quote/details/description",
    name: "salesquotedescription",
    components: {
      body: QuoteDescription,
      sidepanel: null,
    },
    beforeEnter: authGuard,
    //meta: { gtm: "Sales Quote Terms" },
  },
  {
    path: "/projects/quickedit",
    name: "projects-quickedit",
    component: ProjectQuickEdit,
    beforeEnter: authGuard,
    //meta: { gtm: "Project Quick Edit" },
  },

  // {
  //   path: "/search",
  //   name: "search",
  //   component: Search,
  //   beforeEnter: authGuard,
  //   //meta: { gtm: "Search" },
  // },
  {
    path: "/scheduler",
    name: "scheduler",
    component: App,
    beforeEnter: authGuard,
    //meta: { gtm: "Scheduler", title: "Scheduler" },
  },
  {
    path: "/scheduled/jobs",
    name: "scheduled-jobs",
    components: {
      body: () => import("@/views/suppliers/list.vue"),
      sidepanel: () => import("@/views/suppliers/details.vue"),
    },
    beforeEnter: authGuard,
    children: [
      {
        path: "details/:id",
        name: "scheduled-jobs-details",
      },
    ],
  },
  {
    path: "*",
    redirect: "dashboard",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

//Block of code to handle a navigation duplication error issue..
//https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
function patchRouterMethod(router, methodName) {
  router["old" + methodName] = router[methodName];
  router[methodName] = async function(location) {
    return router["old" + methodName](location).catch((error) => {
      if (error.name === "NavigationDuplicated") {
        return this.currentRoute;
      }
      throw error;
    });
  };
}

patchRouterMethod(router, "push");
patchRouterMethod(router, "replace");

export default router;
