
  import Vue from "vue";
  import TableAction from "@/components/table/TableAction.vue";
  import TableHeaderAndRows from "@/components/table/TableHeaderAndRows.vue";
  import TabCustom from "@/components/projects/custom.vue";
  import TabDetails from "@/components/projects/details.vue";
  import TabDescription from "@/components/projects/description.vue";
  import TabProjectEdit from "@/components/projects/projectedit.vue";
  import EmailForm from "@/components/_modals/new-email.vue";
  import EmailBodyTab from "@/components/email/emailbody.vue";
  import EmalAttachmentTab from "@/components/email/emailattachment.vue";
  import {ModalPlugin} from "bootstrap-vue";
  import EVENTS from "@/constants/events";

  Vue.use(ModalPlugin);

  export default Vue.extend({
    name: "EditableTable",
    data() {
      return {
        view: "newProjectView",
      };
    },
    methods: {
      onResetPage() {
        this.$store.dispatch("resetDashboard");
        this.$store.dispatch("getFilterResults", null);
      },
    },
    created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;
    },
    computed: {
      totalResults() {
        return this.$store.getters.getTotal;
      },
    },
    components: {
      TableAction,
      TableHeaderAndRows,
      TabCustom,
      TabDetails,
      TabDescription,
      TabProjectEdit,
      EmailForm,
      EmailBodyTab,
      EmalAttachmentTab,
    },
  });
