
import info from "@/components/_universal/list-info.vue";
import tablelist from "@/components/_universal/list-table.vue";

//Standard Components
import EVENTS from "@/constants/events";

//Controller
import SalesController from "@/controllers/sales-controller";
import listMixin from "@/mixin/list-mixin";
import SalesMetricsComponent from '@/components/sales/sales-metrics-component.vue';


//Initialize Controller and pass data to be loaded/displayed
const salesController = new SalesController();

export default {
  mixins: [listMixin],
  data() {
    return {
      listDataObj: {},
      EVENT_ACTION: {},
      project: {}
    };
  },
  methods: {
    async onFilterChange() {

    },
    async loadData() {

       this.filter.exclude = "";


     
      
    },
  },
  components: {
    SalesMetricsComponent
 
  },
  async mounted() {
   

  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    await this.loadData();
  },
  beforeDestroy() {
   
    

  }
};
