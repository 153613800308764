import { render, staticRenderFns } from "./project-tab-time-tracking.vue?vue&type=template&id=922f506a&scoped=true"
import script from "./project-tab-time-tracking.vue?vue&type=script&lang=ts"
export * from "./project-tab-time-tracking.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "922f506a",
  null
  
)

export default component.exports