
  import Vue from "vue";
  import QuoteServices from "@/services/quotes";
  import UXInventoryService from "@/services/ux/uxinventory";
  import "vue-simple-suggest/dist/styles.css"; // Optional CSS
  import editMixin from "@/mixin/edit-mixin";
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  import _ from "lodash";
  import utilityMixin from "@/mixin/utility-mixin";

  const api = new QuoteServices();
  let global = new GlobalServices();

  export default {
    mixins: [editMixin, utilityMixin],
    name: "QuoteDetails",

    data() {
      return {
        defaultInventorySelected: {
          displayName: "Template/Inventory (Optional)",
          id: "0",
        },
        itemDescriptionLength: 10,
        selectedItem: {},
        quoteDetailsItems: [],
        showErrorWarning: false,
        subTotal: 0,
        inventoryList: [],
        categoryList: [],
        id: 0,
      };
    },

    methods: {
      updateQuantity(i, item) {
        this.calculateItemTotal(i);
        this.updateField(
          "Qty", //PropertyName
          item.qty, //Property Value
          "Quantity", //Display Name
          null,
          null,
          null,
          null,
          null,
          item.quoteDetailId,
          MODULE.QUOTATIONDETAILS.ActionId,
          false, //ignore
          MODULE.QUOTATION.ActionId, //parentActionId
          this.dataObj.data.id // parentId
        );
      },
      updateUnitPrice(i, item) {
        this.calculateItemTotal(i);
        this.updateField(
          "UnitPrice", //PropertyName
          item.unitPrice, //Property Value
          "Unit Price", //Display Name
          null,
          null,
          null,
          null,
          null,
          item.quoteDetailId,
          MODULE.QUOTATIONDETAILS.ActionId,
          false, //ignore
          MODULE.QUOTATION.ActionId, //parentActionId
          this.dataObj.data.id // parentId
        );
      },
      onSelectCategorySuggestion(selectedObj) {
        if (this.selectedItem) {
          if (selectedObj) {
            this.selectedItem.intQuoteTypeID = selectedObj.id;
            this.onDropdownSelectionChange(
              selectedObj,
              "Category/Type", //Display Name
              "uxQuoteType", //Dropdown List Object
              "IntQuoteTypeID", //Edit Details Property Name
              null,
              this.selectedItem.quoteDetailId,
              MODULE.QUOTATIONDETAILS.ActionId,
              false, //ignore
              MODULE.QUOTATION.ActionId, //parentActionId
              this.dataObj.data.id // parentId
            );
          }
        }
      },
      async onSelectSuggestion(selectedObj) {
        if (this.selectedItem) {
          if (selectedObj) {
            let item = await global.getEditDetails(selectedObj.actionId, selectedObj.id);
            this.selectedItem.description =
              item && item.Name && item.Description
                ? `${item.Name}\n${item.Description ? item.Description : ""}`
                : `${selectedObj.displayName}\n${selectedObj.description ? selectedObj.description : ""}`;
            this.selectedItem.qty = item && item.qty && parseFloat(item.qty) !== 0 ? parseFloat(item.qty) : selectedObj.qty && parseFloat(selectedObj.qty) !== 0 ? parseFloat(selectedObj.qty) : 1;
            this.selectedItem.unitPrice = item && item.SalePrice ? item.SalePrice : selectedObj.unitPrice && parseFloat(selectedObj.unitPrice) !== 0 ? parseFloat(selectedObj.unitPrice) : 0;
            //Handles longer text discription being passed from templates
            // if (selectedObj.description) {
            //   this.selectedItem.description = selectedObj.description;
            // } else {
            //   this.selectedItem.description = selectedObj.displayName;
            // }

            this.updateField(
              "Description", //PropertyName
              this.selectedItem.description, //Property Value
              "Description", //Display Name
              null,
              null,
              null,
              null,
              null,
              this.selectedItem.quoteDetailId,
              this.MODULE.QUOTATIONDETAILS.ActionId,
              false, //ignore
              MODULE.QUOTATION.ActionId, //parentActionId
              this.dataObj.data.id // parentId
            );

            // this.selectedItem.unitPrice = selectedObj.unitPrice;
            this.selectedItem.amount = this._.multiply(this.selectedItem.qty, this.selectedItem.unitPrice);
            this.calculateTotalSum();
          }
        }
      },
      async onLoadInventory() {
        let inventoryService = new UXInventoryService();

        //Hack to pass additional paramaters to inventory list
        let queryString = "&actionId=" + MODULE.QUOTATION.ActionId;

        this.inventoryList = await inventoryService.getInventoryList(queryString);
      },
      getSuggestionList() {
        let inventoryService = new UXInventoryService();
        let desc = _.unescape(this.selectedItem.description);
        this.inventoryList = inventoryService.getInventoryList(desc);

        return this.inventoryList;
      },
      async saveRow(item) {
        this.selectedItem = null;
        item.description = this.htmlEncodeCharacters(item.description);
        const payload = {
          ...item,
          parentActionID: MODULE.QUOTATION.ActionId,
          parentId: this.dataObj.data.id,
        };
        await api.updateData(payload);
        this.calculateTotalSum();
        await this.reloadItemList();
      },
      async reloadItemList() {
        this.quoteDetailsItems = await api.getQuoteDetails(this.dataObj.data.id);
      },
      async saveItem() {
        try {
          this.$root.$emit(this.savingEvent, true);
          const payload = {
            ...this.selectedItem,
            parentActionID: MODULE.QUOTATION.ActionId,
            parentId: this.dataObj.data.id,
          };
          let item = await api.updateData(payload);
          let index = _.findIndex(this.quoteDetailsItems, (o: any) => {
            return o.quoteDetailId === this.selectedItem.quoteDetailId;
          });

          this.quoteDetailsItems[index] = item.data.resources;
          this.calculateTotalSum();
          this.setRowDetails(index);
          this.$root.$emit(this.savedEvent, true);
        } catch (error) {
          this.$root.$emit(this.errorEvent, true);
        }
      },
      setRowDetails(index) {
        this.selectedItem = this.quoteDetailsItems[index];
        this.selectedItem.description = this.htmlDecodeCharacters(this.quoteDetailsItems[index].description);
      },
      editRow(index) {
        this.setRowDetails(index);
        if (this.selectedItem) {
          this.saveItem();
        }
      },
      calculateItemTotal(index) {
        if (this.selectedItem) {
          let qty = this.selectedItem.qty;
          let unitPrice = this.selectedItem.unitPrice;
          this.selectedItem.amount = this._.multiply(qty, unitPrice);

          this.quoteDetailsItems[index] = this.selectedItem;
        }
        this.calculateTotalSum();
      },
      calculateTotalSum() {
        this.subTotal = this._.sumBy(this.quoteDetailsItems, (item) => item.amount);
        let formattedValue = this.$options.filters.currency(this.subTotal);
        //Broadcast to any component that care, that the total was changes...
        this.$root.$emit(this.EVENT_ACTION.INFO.UpdateTotal, formattedValue);
      },
      showHideLabel(index) {
        //If we're in disable edit mode, hide options
        if (this.isDisabled) {
          return false;
        } else {
          if (this.isEditingCurrentRow(index)) return false;
          else return true;
        }
      },
      showHideTextBox(index) {
        if (this.isEditingCurrentRow(index)) return true;
        else return false;
      },
      isEditingCurrentRow(index) {
        if (this.selectedItem === this.quoteDetailsItems[index]) return true;
        else return false;
      },

      async deleteItem(index) {
        await api.deleteItem(this.quoteDetailsItems[index]);
        this.quoteDetailsItems.splice(index, 1);
        this.selectedItem = null;
        this.calculateTotalSum();
      },
      async cancelRow(index) {
        if (this.isEditingLastRow()) {
          if (!this.isLastItemRowValid()) this.deleteItem(index);
        }
        this.showErrorWarning = false;
        this.selectedItem = null;

        await this.reloadItemList();
      },
      addNewItem() {
        const newQuoteLineItem = {
          qid: this.dataObj.data.id,
          quoteDetailId: 0,
          description: "",
          intQuoteTypeID: 1,
          qty: 1,
          unitPrice: 0,
          amount: null,
        };

        let shouldAddNewItem = false;

        //Evaluate the last row before adding new item row
        if (this.isLastItemRowValid()) {
          shouldAddNewItem = true;
        } else if (this.quoteDetailsItems.length === 0) {
          shouldAddNewItem = true;
        }

        if (shouldAddNewItem) {
          this.quoteDetailsItems.push(newQuoteLineItem);
          this.editRow(this.quoteDetailsItems.length - 1);
        }
      },
      isLastItemRowValid() {
        //Get the last Item row and ensure that it has values, before allowing user to add additional rows
        let allowAddingNewRow = false;

        if (this.quoteDetailsItems.length > 0) {
          //If current select item is the last row
          if (this.isEditingLastRow()) {
            //If they are no items in the last row then show error message.
            if (this.selectedItem.description === "" && this.selectedItem.qty === 1 && this.selectedItem.unitPrice === 0) {
              this.showErrorWarning = true;
              setTimeout(() => (this.showErrorWarning = false), 4000);
            } else {
              allowAddingNewRow = true;

              //If the last row is not blank, then save it to databse.
              this.saveRow(this.quoteDetailsItems.length - 1);
            }
          } else {
            allowAddingNewRow = true;
          }
        }

        return allowAddingNewRow;
      },
      isEditingLastRow() {
        if (this.selectedItem == this.quoteDetailsItems[this.quoteDetailsItems.length - 1]) return true;

        return false;
      },
      initializeData() {
        const newQuoteLineItem = {
          qid: this.dataObj.data.id,
          quoteDetailId: 0,
          description: "",
          intQuoteTypeID: 1,
          qty: 1,
          unitPrice: 0,
          amount: null,
        };

        this.quoteDetailsItems.push(newQuoteLineItem);
        this.editRow(this.quoteDetailsItems.length - 1);
      },
    },
    async mounted() {
      if (this.currentDataObj.details.JobID) {
        this.isDisabled = this.currentDataObj.details.IsLockedFromEditing !== null ? this.currentDataObj.details.IsLockedFromEditing : true;
      } else {
        this.isDisabled = false;
      }

      await this.onLoadInventory();
    },
    async created() {
      this.MODULE = MODULE;
      this.quoteDetailsItems = await api.getQuoteDetails(this.dataObj.data.id);

      if (this.quoteDetailsItems.length === 0) this.initializeData();
      else this.calculateTotalSum();
    },
  };
