
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  import SmallCard from "@/components/_cards/small-card.vue";
  import GenericTable from "@/components/listTable/generic-table.vue";
  import UXTechnicianService from "@/services/ux/uxtechnicians";
  import ApplicationService from "@/services/application";
  import DashboardService from "@/services/dashboard";
  import {DashboardFilter} from "@/interfaces/filters/DashboardFilters";
  import BarChart from "@/components/charts/bar-chart.vue";
  import PieChart from "@/components/charts/pie-chart.vue";
  import {debounce} from "lodash/function";
  import moment from "moment";
  import EVENTS from "@/constants/events";
  import charts from "@/mixin/charts-mixin";
  const app = new ApplicationService();
  let global = new GlobalServices();
  let tech = new UXTechnicianService();

  export default {
    mixins: [charts],
    data() {
      return {
        valueDate: [],
        listDataObj: {},
        EVENT_ACTION: {},
        actionId: null,
        moduleId: null,
        id: null,
        parentActionId: null,
        moduleName: null,
        dropDownList: [
          {displayName: "Website", id: 1},
          {displayName: "Email", id: 2},
          {displayName: "Referral", id: 3},
        ],
        selectedRadio: "thismonth",
        options: [
          {text: "This Week", value: "thisweek"},
          {text: "Last Week", value: "lastweek"},
          {text: "This Month", value: "thismonth"},
          {text: "This Year", value: "thisyear"},
        ],
        mockStats: [
          {
            id: "1116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Total Hours Logged",
            primaryNumber: "0",
            primarySymbol: "h",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "purple",
          },
          {
            id: "4116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Regular Time",
            primaryNumber: "0",
            primarySymbol: "h",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "orange",
          },
          {
            id: "3116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Over Time",
            primaryNumber: "0",
            primarySymbol: "h",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "red",
          },
          {
            id: "2116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Travel Time",
            primaryNumber: "0",
            primarySymbol: "h",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "green",
          },
        ],
        barDataSets: {},
        pieDataSets: {},
        headers: [
          {
            id: 1,
            title: "Technician",
            columnName: "technician",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 10,
            title: "",
            columnName: "tooltip",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 2,
            title: "Date",
            columnName: "date",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
            width: "10%;",
          },
          {
            id: 3,
            title: "Reference Number",
            columnName: "referenceNumber",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
            clickable: true,
          },
          {
            id: 4,
            title: "Description",
            columnName: "description",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 5,
            title: "Type",
            columnName: "type",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 6,
            title: "Time In",
            columnName: "timeIn",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 7,
            title: "Time Out",
            columnName: "timeOut",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 8,
            title: "Travel",
            columnName: "travel",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 9,
            title: "Total",
            columnName: "total",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
        ],
        items: [],
        uxTechniciansList: [],
        technicianSelected: null,
        uxClientsList: [],
        clientSelected: null,
        uxWorkOrderList: [],

        workOrderSelected: null,
        filters: {},
      };
    },
    computed: {
      getSelectedRadioText() {
        const option = this.options.find((d) => d.value === this.selectedRadio);
        return option.text;
      },
      dateRangeDefaultValue() {
        const format = "DD-MMM-YYYY";
        const currentDate = moment().format(format);
        return [
          moment(currentDate)
            .add(1, "M")
            .format(format),
          currentDate,
        ];
      },
    },
    watch: {
      valueDate(value) {
        if (value && value.length === 2 && value[0] !== "" && value[1] !== "") {
          this.selectedRadio = null;
        } else {
          this.selectedRadio = "thismonth";
        }
        this.loadTable();
      },
      selectedRadio(value) {
        if (value) {
          if (this.valueDate.length > 0) this.valueDate = [];
          this.loadAllRequiredData();
        }
      },
    },
    components: {
      SmallCard,
      GenericTable,
      BarChart,
      PieChart,
    },
    methods: {
      onSearchWorkOrder(search, loading) {
        if (search.length > 0) {
          loading(true);
          this.searchWorkOrder(loading, search, this);
        }
      },
      searchWorkOrder: debounce(async (loading, search, vm) => {
        vm.uxWorkOrderList = await global.getUXDropDown(MODULE.DASHBOARD.ActionId, null, null, null, null, null, {disabled: false, deleted: false}, search);
        loading(false);
      }, 500),
      async reloadCharts() {
        const params = {
          actionId: MODULE.TASKS.ActionId,
          moduleId: MODULE.PROJECTS.ModuleId,
          DateFilter: this.selectedRadio,
        } as any;

        if (this.clientSelected) {
          params.ClientId = this.clientSelected;
        }

        if (this.technicianSelected) {
          params.EmployeeId = this.technicianSelected;
        }

        if (this.workOrderSelected) {
          params.ProjectId = this.workOrderSelected.id;
        }

        if (this.valueDate && this.valueDate.length === 2 && this.valueDate[0] !== "" && this.valueDate[1] !== "") {
          params.StartDate = this.valueDate[0];
          params.EndDate = this.valueDate[1];
        }

        this.barDataSets = await this.getChartsData(params, "bar", "", false, ["Total Logged Hours", "Available Hours"], [false, true]);
        this.pieDataSets = await this.getChartsData(params, "pie", "TimeTrackingType", false);
      },
      async showPanel(data) {
        if (data) {
          //Set Id of the currently selected/edit table item
          let currentPath = this.$route.path + "/" + data.intProjectID;

          let route = {name: "projects-details", params: {id: data.intProjectID}};

          //Update the router
          if (currentPath !== this.$route.path) {
            this.$router.push({name: `${route.name}`, params: {id: `${data.intProjectID}`}});
          }

          let filter = {} as DashboardFilter;

          filter.projectRefNumber = data.intProjectID;

          const dashboardService = new DashboardService();

          let results = await dashboardService.getProjects(filter);
          if (results.resources.length > 0) {
            const project = results.resources.find((p) => p.intProjectID === data.intProjectID);
            app.showSidePanel(null, project, route);
          }
        }
      },
      async loadData() {
        this.id = 0;
        this.parentActionId = MODULE.PROJECTS.ActionId;
        this.moduleName = "Time Tracking";
        this.actionId = MODULE.TIMETRACKING.ActionId;
        this.moduleId = MODULE.PROJECTS.ModuleId;
        this.uxTechniciansList = await tech.getTechnicians();
        this.uxClientsList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
        this.uxWorkOrderList = await global.getUXDropDown(MODULE.DASHBOARD.ActionId);
        this.loadTable();
      },
      loadAllRequiredData() {
        this.loadTable();
        this.reloadCharts();
      },
      async downloadExcel() {
        const res: any = await global.getTableReportDataByActionId({
          ...this.filters,
          export: "excel",
        });
        if (!Array.isArray(res)) window.open(res, "_blank");
      },
      async loadTable() {
        try {
          //Set Header Properties to be passed in as prop values
          this.filters = {
            // id: this.id,
            // ProjectId: this.id,
            // parentActionId: this.parentActionId,
            actionId: this.actionId,
            moduleId: this.moduleId,
          } as any;

          if (this.clientSelected) {
            this.filters.ClientId = this.clientSelected;
          }

          if (this.selectedRadio) {
            this.filters.dateFilter = this.selectedRadio;
          }

          if (this.technicianSelected) {
            this.filters.EmployeeId = this.technicianSelected;
          }

          if (this.workOrderSelected) {
            this.filters.ProjectId = this.workOrderSelected.id;
          }

          if (this.valueDate && this.valueDate.length === 2 && this.valueDate[0] !== "" && this.valueDate[1] !== "") {
            this.filters.StartDate = this.valueDate[0];
            this.filters.EndDate = this.valueDate[1];
          }

          const results = await global.getDataReportsList(this.filters);
          this.listDataObj = results.table;
          const headers = this.listDataObj.header;
          headers.splice(1, 0, {
            id: 999,
            title: "",
            columnName: "tooltip",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
            smallest: true,
          });
          this.headers = headers;

          this.items = this.listDataObj.data;
          this.mockStats = this.listDataObj.stats;
        } catch (e) {
          // keep trying until data loads back
          this.loadTable();
        }
      },
    },
    async mounted() {
      await this.loadData();
      this.reloadCharts();
      //Reload Data every 5 seconds
      this.timer = setInterval(async () => {
        await this.loadTable();
        await this.reloadCharts();
      }, 5000);
    },
    beforeDestroy() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = false;
    },
    created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;
    },
  };
