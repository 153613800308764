var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('label',[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Description),expression:"currentDataObj.details.Description"}],staticClass:"form-control text-left",attrs:{"id":_vm.$route.name+'-tab-details-description',"rows":"8","type":"text"},domProps:{"value":(_vm.currentDataObj.details.Description)},on:{"keyup":function($event){return _vm.updateField(
        'Description', //PropertyName
        _vm.currentDataObj.details.Description, //Property Value
        'Description', //Display Name,
        _vm.currentDataObj.details.Description, //Display Value
        'description' //Data object Property Name
      )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "Description", $event.target.value)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }