import { render, staticRenderFns } from "./notes-universal-notes.vue?vue&type=template&id=b8a13544&scoped=true"
import script from "./notes-universal-notes.vue?vue&type=script&lang=ts"
export * from "./notes-universal-notes.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8a13544",
  null
  
)

export default component.exports