var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Billing Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.BillingName),expression:"currentDataObj.details.BillingName"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-billing-and-account-receivable-billing-name","type":"text"},domProps:{"value":(_vm.currentDataObj.details.BillingName)},on:{"keyup":function($event){return _vm.updateField(
            'BillingName', //PropertyName
            _vm.currentDataObj.details.BillingName, //Property Value
            'Billing Name' //Display Name
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "BillingName", $event.target.value)}}})]),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Parent/Reseller Company")]),_vm._v("  "),(_vm.currentDataObj.details.IntParentClientID)?_c('span',{staticClass:"small clickable",on:{"click":_vm.removeCompany}},[_vm._v("(remove)")]):_vm._e(),_c('v-select',{attrs:{"options":_vm.UXData.uxClientList,"id":"slidepanel-client-details-tabs-billing-and-account-receivable-parent-reseller-company","value":_vm.getSelectedDropDownValue('uxClientList', 'IntParentClientID'),"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
            $event,
            'Parent / Reseller Company', //Display Name
            'uxClientList', //Dropdown List Object
            'IntParentClientID' //Edit Details Property Name
          )}}})],1),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("A/R Contact Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ARContactName),expression:"currentDataObj.details.ARContactName"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-billing-and-account-receivable-A-R-contact-name"},domProps:{"value":(_vm.currentDataObj.details.ARContactName)},on:{"keyup":function($event){return _vm.updateField(
            'ARContactName', //PropertyName
            _vm.currentDataObj.details.ARContactName, //Property Value
            'A/R ContactName' //Display Name
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "ARContactName", $event.target.value)}}})]),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("A/R Contact Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ARContactEmailAddress),expression:"currentDataObj.details.ARContactEmailAddress"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-billing-and-account-receivable-A-R-contact-email"},domProps:{"value":(_vm.currentDataObj.details.ARContactEmailAddress)},on:{"keyup":function($event){return _vm.updateField(
            'ARContactEmailAddress', //PropertyName
            _vm.currentDataObj.details.ARContactEmailAddress, //Property Value
            'A/R Contact Email' //Display Name
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "ARContactEmailAddress", $event.target.value)}}})]),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Account Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.AccountNumber),expression:"currentDataObj.details.AccountNumber"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-billing-and-account-receivable-account-number"},domProps:{"value":(_vm.currentDataObj.details.AccountNumber)},on:{"keyup":function($event){return _vm.updateField(
            'AccountNumber', //PropertyName
            _vm.currentDataObj.details.AccountNumber, //Property Value
            'Account Number' //Display Name
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "AccountNumber", $event.target.value)}}})]),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('global-settings-modal',{attrs:{"label":"Preferred Payment Method","actionId":_vm.MODULE.PAYMENT.UX.PaymentType}}),_c('v-select',{attrs:{"options":_vm.UXData.uxPaymentType,"id":"slidepanel-client-details-tabs-billing-and-account-receivable-preferred-payment-method","value":_vm.getSelectedDropDownValue('uxPaymentType', 'IntPreferredPaymentTypeID'),"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
            $event,
            'Preferred Payment Method', //Display Name
            'uxPaymentType', //Dropdown List Object
            'IntPreferredPaymentTypeID' //Edit Details Property Name
          )}}})],1),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('global-settings-modal',{attrs:{"label":"Payment Terms","actionId":_vm.MODULE.PAYMENT.UX.PaymentTerms,"id":"slidepanel-client-details-tabs-billing-and-account-receivable-"}}),_c('v-select',{attrs:{"clearable":false,"id":"slidepanel-client-details-tabs-billing-and-account-receivable-global-setting-payment-terms","options":_vm.UXData.uxPaymentTerms,"value":_vm.getSelectedDropDownValue('uxPaymentTerms', 'IntPayTermID'),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
            $event,
            'Payment Terms', //Display Name
            'uxPaymentTerms', //Dropdown List Object
            'IntPayTermID' //Edit Details Property Name
          )}}})],1),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("A/R Contact Phone Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ARContactPhoneNumber),expression:"currentDataObj.details.ARContactPhoneNumber"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-billing-and-account-receivable-A-R-contact-phone-number","type":"text"},domProps:{"value":(_vm.currentDataObj.details.ARContactPhoneNumber)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "ARContactPhoneNumber", $event.target.value)},function($event){return _vm.formatPhoneNumber('ARContactPhoneNumber')}],"keyup":function($event){return _vm.updateField(
            'ARContactPhoneNumber', //PropertyName
            _vm.currentDataObj.details.ARContactPhoneNumber, //Property Value
            'A/R Contact Phone Number' //Display Name
          )}}})]),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Ext.")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ARContactExtension),expression:"currentDataObj.details.ARContactExtension"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-billing-and-account-receivable-ext"},domProps:{"value":(_vm.currentDataObj.details.ARContactExtension)},on:{"keyup":function($event){return _vm.updateField(
            'ARContactExtension', //PropertyName
            _vm.currentDataObj.details.ARContactExtension, //Property Value
            'A/R Contact Phone Ext.' //Display Name
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "ARContactExtension", $event.target.value)}}})]),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('global-settings-modal',{attrs:{"label":"Groups","actionId":_vm.MODULE.CLIENTS.UX.ClientRateGroup,"id":"slidepanel-client-details-tabs-billing-and-account-receivable-global-setting-client-rate-group"}},[_c('a',{staticClass:"link ml-auto",on:{"click":function($event){$event.preventDefault();return _vm.showModal(_vm.EVENT_ACTION.SETTINGS.ClientRatingListing, `Edit Rates`, _vm.MODULE.CLIENTS.UX.ClientRateGroup)}}},[_vm._v(" Edit Rates")])]),_c('v-select',{attrs:{"options":_vm.UXData.uxClientRateGroup,"id":"slidepanel-client-details-tabs-billing-and-account-receivable-client-rate-group","value":_vm.getSelectedDropDownValue('uxClientRateGroup', 'IntClientRateGroupID'),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
            $event,
            'Group Rates', //Display Name
            'uxClientRateGroup', //Dropdown List Object
            'IntClientRateGroupID' //Edit Details Property Name
          )}}})],1),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('global-settings-modal',{attrs:{"label":"Client Rating","actionId":_vm.MODULE.CLIENTS.UX.ClientRating,"id":"slidepanel-client-details-tabs-billing-and-account-receivable-global-setting-client-rating"}}),_c('v-select',{attrs:{"options":_vm.UXData.uxClientRating,"id":"slidepanel-client-details-tabs-billing-and-account-receivable-client-rating","clearable":false,"value":_vm.getSelectedDropDownValue('uxClientRating', 'IntClientRatingID'),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
            $event,
            'Client Rating', //Display Name
            'uxClientRating', //Dropdown List Object
            'IntClientRatingID' //Edit Details Property Name
          )}}})],1),_c('div',{staticClass:"col-lg-3 col-md-6 mb-3"},[_c('global-settings-modal',{attrs:{"label":"Tax Code","actionId":_vm.MODULE.INVOICE.UX.InvoiceTaxRates,"id":"slidepanel-client-details-tabs-billing-and-account-receivable-global-setting-tax-code"}}),_c('v-select',{attrs:{"id":"slidepanel-client-details-tabs-billing-and-account-receivable-client-tax-code","options":_vm.UXData.uxTaxRate,"clearable":false,"value":_vm.getSelectedDropDownValue('uxTaxRate', 'IntTaxCodeID'),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
            $event,
            'Tax Rate', //Display Name
            'uxTaxRate', //Dropdown List Object
            'IntTaxCodeID' //Edit Details Property Name
          )}}})],1),_c('div',{staticClass:"col-lg-6 col-md-12 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("A/R Notes")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ARClientNotes),expression:"currentDataObj.details.ARClientNotes"}],staticClass:"form-control",attrs:{"rows":"5","type":"text","id":"slidepanel-client-details-tabs-billing-and-account-receivable-A-R-notes"},domProps:{"value":(_vm.currentDataObj.details.ARClientNotes)},on:{"keyup":function($event){return _vm.updateField(
            'ARClientNotes', //PropertyName
            _vm.currentDataObj.details.ARClientNotes, //Property Value
            'Billing or A/R Notes' //Display Name
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "ARClientNotes", $event.target.value)}}})]),_c('div',{staticClass:"col-lg-6 col-md-12 u-mb-xsmall pt-4 checkboxes"},[_c('b-form-checkbox',{attrs:{"id":"chkIsCOD","name":"chkIsCOD"},on:{"change":function($event){_vm.checkboxChanged(
            'IsCOD', //PropertyName
            _vm.currentDataObj.details.IsCOD, //Property Value
            'Require Cash On Delivery (COD)' //Display Name
          )}},model:{value:(_vm.currentDataObj.details.IsCOD),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "IsCOD", $$v)},expression:"currentDataObj.details.IsCOD"}},[_vm._v(" Require Cash On Delivery (COD) ")]),_c('b-form-checkbox',{attrs:{"id":"chkRequirePO","name":"chkRequirePO"},on:{"change":function($event){return _vm.checkboxChanged(
            'RequirePO', //PropertyName
            _vm.currentDataObj.details.RequirePO, //Property Value
            'Required Purchase Order Number' //Display Name
          )}},model:{value:(_vm.currentDataObj.details.RequirePO),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "RequirePO", $$v)},expression:"currentDataObj.details.RequirePO"}},[_vm._v(" Required Purchase Order Number (before sending invoice) ")]),_c('b-form-checkbox',{attrs:{"id":"chkFlagAccountForBillingIssues","name":"chkFlagAccountForBillingIssues"},on:{"change":function($event){_vm.checkboxChanged(
            'FlagAccountForBillingIssues', //PropertyName
            _vm.currentDataObj.details.FlagAccountForBillingIssues, //Property Value
            'Flag this account (Not in good standing)' //Display Name
          )}},model:{value:(_vm.currentDataObj.details.FlagAccountForBillingIssues),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "FlagAccountForBillingIssues", $$v)},expression:"currentDataObj.details.FlagAccountForBillingIssues"}},[_vm._v(" Flag this account (Not in good standing) ")]),_c('b-form-checkbox',{attrs:{"id":"chkRequirePaymentBeforeCreatingNewProjects","name":"chkRequirePaymentBeforeCreatingNewProjects"},on:{"change":function($event){return _vm.checkboxChanged(
            'RequirePaymentBeforeCreatingNewProjects', //PropertyName
            _vm.currentDataObj.details.RequirePaymentBeforeCreatingNewProjects, //Property Value
            'Disable creating jobs for this client, until payment is received.' //Display Name
          )}},model:{value:(_vm.currentDataObj.details.RequirePaymentBeforeCreatingNewProjects),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "RequirePaymentBeforeCreatingNewProjects", $$v)},expression:"currentDataObj.details.RequirePaymentBeforeCreatingNewProjects"}},[_vm._v(" Disable creating jobs for this client, until payment is received. ")]),_c('b-form-checkbox',{attrs:{"id":"chkAccountInGoodStanding","name":"chkAccountInGoodStanding"},on:{"change":function($event){_vm.checkboxChanged(
            'AccountInGoodStanding', //PropertyName
            _vm.currentDataObj.details.AccountInGoodStanding, //Property Value
            'Disable all A/R Notifications (Account is in good standing)' //Display Name
          )}},model:{value:(_vm.currentDataObj.details.AccountInGoodStanding),callback:function ($$v) {_vm.$set(_vm.currentDataObj.details, "AccountInGoodStanding", $$v)},expression:"currentDataObj.details.AccountInGoodStanding"}},[_vm._v(" Disable all email alert notifications (when a/c is not in good standing) ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }