
  import moment from "moment";
  import NotesService from "@/services/notes";
  import "vue-simple-suggest/dist/styles.css"; // Optional CSS

  const api = NotesService.getInstance();
  const PERMITS_NOTETYPE = 5;
  let _projectId = 0;

  //Default line item state
  const newLineItem = {
    id: 0,
    typeId: 5, //set default type as permit
    projectId: _projectId,
    referenceNumber: "",
    status: "",
    description: "",
    classification: "",
    expiryDate: "",
    notes: "",
  };

  export default {
    name: "PermitNotes",

    data() {
      return {
        selectedItem: {},
        itemList: [],
        showErrorWarning: false,
        lookupList: [],
      };
    },

    methods: {
      async onLoadList() {},
      formatDate(value) {
        if (value) {
          return moment(String(value)).format("DD-MMM-YYYY");
        }
      },
      async saveRow(index) {
        this.selectedItem = null;

        let result = await api.updateData(this.itemList[index]);

        //Assign newly inserted id
        this.itemList[index] = result;
      },
      editRow(index) {
        this.selectedItem = this.itemList[index];
      },

      showHideLabel(index) {
        if (this.isEditingCurrentRow(index)) return false;
        else return true;
      },
      showHideTextBox(index) {
        if (this.isEditingCurrentRow(index)) return true;
        else return false;
      },
      isEditingCurrentRow(index) {
        if (this.selectedItem === this.itemList[index]) return true;
        else return false;
      },

      async deleteItem(index) {
        await api.deleteItem(this.itemList[index]);
        this.itemList.splice(index, 1);
        this.selectedItem = null;
      },
      cancelRow(index) {
        if (this.isEditingLastRow()) {
          if (!this.isLastItemRowValid()) this.deleteItem(index);
        }
        this.showErrorWarning = false;
        this.selectedItem = null;
      },
      addNewItem() {
        let shouldAddNewItem = false;

        //Evaluate the last row before adding new item row
        if (this.isLastItemRowValid()) {
          shouldAddNewItem = true;
        } else if (this.itemList.length === 0) {
          shouldAddNewItem = true;
        }

        const newLineItem2 = {
          id: 0,
          projectId: _projectId,
          typeId: 5, //set default type as permit
          referenceNumber: "",
          status: "",
          description: "",
          classification: "",
          expiryDate: "",
          notes: "",
        };

        if (shouldAddNewItem) {
          this.itemList.push(newLineItem2);
          this.editRow(this.itemList.length - 1);
        }
      },
      isLastItemRowValid() {
        //Get the last Item row and ensure that it has values, before allowing user to add additional rows
        let allowAddingNewRow = false;

        if (this.itemList.length > 0) {
          //If current select item is the last row
          if (this.isEditingLastRow()) {
            //If they are no items in the last row then show error message.
            if (this.selectedItem.id == 0 && (this.selectedItem.referenceNumber === "" || this.selectedItem.description === "")) {
              this.showErrorWarning = true;
              setTimeout(() => (this.showErrorWarning = false), 4000);
            } else {
              allowAddingNewRow = true;

              //If the last row is not blank, then save it to databse.
              this.saveRow(this.itemList.length - 1);
            }
          } else {
            allowAddingNewRow = true;
          }
        }

        return allowAddingNewRow;
      },
      isEditingLastRow() {
        if (this.selectedItem == this.itemList[this.itemList.length - 1]) return true;

        return false;
      },
    },
    async mounted() {
      //Get Project Id
      _projectId = this.$route.query.pid;

      this.itemList = await api.getUniversalNotes(_projectId, PERMITS_NOTETYPE);
    },
    async created() {},
  };
