

import infoData from "@/components/_universal/info-stats.vue";
import infoMetaData from "@/components/_universal/info-stats-meta.vue";

export default {
  props: {
    dataObj: {
      type: Object,
      required: true
    }
  },
  data() {
    return { pageTitle: "" };
  },
  components: {
    infoData,
    infoMetaData
  },
};
