var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Change Terms & Conditions")]),_c('v-select',{attrs:{"options":_vm.uxTemplateList,"value":_vm.id,"code":"id","label":"displayName"},on:{"input":_vm.onSelectTerms},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1),_c('div',{staticClass:"col-md-12 u-mt-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Quote Terms")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.template.description),expression:"template.description"}],staticClass:"form-control",attrs:{"rows":"15","type":"text","placeholder":"","id":"dashboard-top-nav-new-client-modal-address"},domProps:{"value":(_vm.template.description)},on:{"keyup":function($event){return _vm.updateField(
            'TermsConditions', //PropertyName
            _vm.template.description, //Property Value
            'Terms & Conditions' //Display Name
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.template, "description", $event.target.value)}}})]),_c('div',{staticClass:"col-md-12 u-mt-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Terms Section 2")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.template.termsSection2),expression:"template.termsSection2"}],staticClass:"form-control",attrs:{"rows":"15","type":"text","placeholder":"","id":"dashboard-top-nav-new-client-modal-address"},domProps:{"value":(_vm.template.termsSection2)},on:{"keyup":function($event){return _vm.updateField(
            'ContentSection3', //PropertyName
            _vm.template.termsSection2, //Property Value
            'Terms & Conditions Section 2' //Display Name
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.template, "termsSection2", $event.target.value)}}})]),_c('div',{staticClass:"col-md-12 u-mt-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Payment/Pricing Terms")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.template.termsSection3),expression:"template.termsSection3"}],staticClass:"form-control",attrs:{"rows":"15","type":"text","placeholder":"","id":"dashboard-top-nav-new-client-modal-address"},domProps:{"value":(_vm.template.termsSection3)},on:{"keyup":function($event){return _vm.updateField(
            'RequireDepositAdvanceLabel', //PropertyName
            _vm.template.termsSection3, //Property Value
            'Payment/Pricing Terms' //Display Name
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.template, "termsSection3", $event.target.value)}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }