
  import listFilter from "@/components/_universal/list-filter.vue";
  import infoData from "@/components/_universal/info-stats.vue";
  import filters from "@/components/_universal/filters/filters.vue";
  import MODULE from "@/constants/modules";

  export default {
    props: {
      listDataObj: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {pageTitle: ""};
    },
    components: {
      listFilter,
      infoData,
      filters,
    },
    mounted() {
      this.pageTitle = this.$route.meta.gtm;
    },
    created() {
      this.MODULE = MODULE;
    },
  };
