
  import info from "@/components/_universal/list-info.vue";
  import tablelist from "@/components/_universal/list-table.vue";

  //Standard Components
  import EVENTS from "@/constants/events";

  //Controller
  import SalesController from "@/controllers/sales-controller";
  import listMixin from "@/mixin/list-mixin";
  import SalesPerformance from "@/views/sales/sales-performance.vue";
  import SalesReport from "@/views/sales/sales-report.vue";

  //Initialize Controller and pass data to be loaded/displayed
  const salesController = new SalesController();

  export default {
    mixins: [listMixin],
    data() {
      return {
        listDataObj: {},
        EVENT_ACTION: {},
        project: {},
      };
    },
    methods: {
      async onFilterChange() {
        //Get current filter from store
        if (this.$store.getters.getList.filter) this.filter = this.$store.getters.getList.filter;

        //Call API pass new filter object paramater
        let data = await salesController.getListResults(this.filter);

        //Update Store results
        await this.updateResults(data.resources);
      },
      async loadData() {
        this.filter.exclude = "";

        //Set Header Properties to be passed in as prop values
        this.listDataObj = await salesController.list();

        //Save the current list in the store so we can reference it if it updates.
        if (this.listDataObj.table.data.success) {
          this.updateResults(this.listDataObj.table.data.resources);
        }

        //Reload Data every 5 seconds
        this.timer = setInterval(async () => {
          await this.onFilterChange();
        }, 5000);
      },

      //Add results to the store
      async updateResults(results) {
        this.$store.dispatch("setList", results);
      },

      //Check for changes, if the user changed the filter, to refresh the api
      subscribeOnFilterChange() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
          if (mutation.type === "setFilter") {
            this.onFilterChange();
          }
        });
      },
    },
    components: {
      info,
      tablelist,
      SalesPerformance,
      SalesReport,
    },
    async mounted() {
      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window["analytics"].page(this.EVENT_ACTION.QUOTATION.View);
      // }

      this.subscribeOnFilterChange();
    },
    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      await this.loadData();
    },
    beforeDestroy() {
      //Remove data from vuex store
      this.updateResults(null);

      this.unsubscribe();
    },
  };
