var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.document)?_c('div',[_c('ValidationObserver',{ref:"formDocumentEditor"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.isSuccesful)?_c('b-alert',{staticClass:"mb-2",attrs:{"show":"","variant":"success"}},[_c('i',{staticClass:"fa fa-check-circle mr-2"}),_c('span',{staticClass:"sub-title mr-2",attrs:{"id":"modal-new-client-success-message"}},[_vm._v("Succesfully updated document. You may close this window.")])]):_vm._e()],1),_c('div',{staticClass:"col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v(" Name ")]),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.document.documentName),expression:"document.documentName"}],staticClass:"form-control",attrs:{"type":"text","id":"modal-document-name"},domProps:{"value":(_vm.document.documentName)},on:{"keyup":function($event){return _vm.updateField(
                'DocumentName', //PropertyName
                _vm.document.documentName, //Property Value
                'Document Name', //Display Name
                _vm.document.documentName, //Display Value
                null, //dataObjPropertyName?,
                null, //previousDisplayValue?,
                null, //previousValueId?,
                null, //isModal?,
                _vm.document.id, //Current Item Row Id
                _vm.MODULE.DOCUMENTS.ActionId
              )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.document, "documentName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(v.errors[0]))])]}}],null,false,12236429)})],1),_c('div',{staticClass:"col-md-6 col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v(" Document Type ")]),_c('v-select',{attrs:{"options":_vm.uxDocumentTypeList,"clearable":false,"value":"id","label":"displayName","id":"modal-document-type"},on:{"input":_vm.onUpdateSelection},model:{value:(_vm.selectedDocumentType),callback:function ($$v) {_vm.selectedDocumentType=$$v},expression:"selectedDocumentType"}})],1),_c('div',{staticClass:"col-md-12 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v(" Description ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.document.notes),expression:"document.notes"}],staticClass:"form-control",attrs:{"type":"text","id":"modal-document-description","rows":"4"},domProps:{"value":(_vm.document.notes)},on:{"keyup":function($event){return _vm.updateField(
              'Description', //PropertyName
              _vm.document.notes, //Property Value
              'Document Description', //Display Name
              _vm.document.notes, //Display Value
              null, //dataObjPropertyName?,
              null, //previousDisplayValue?,
              null, //previousValueId?,
              null, //isModal?,
              _vm.document.id, //Current Item Row Id
              _vm.MODULE.DOCUMENTS.ActionId
            )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.document, "notes", $event.target.value)}}})])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }