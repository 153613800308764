
import contactDetails from "@/views/contacts/details.vue";
export default {
  data() {
    return {
      
    };
  },
  methods: {
  },
  components: {
    contactDetails
  },
};
