
  import MODULE from "@/constants/modules";
  import editMixin from "@/mixin/edit-mixin";
  import GlobalServices from "@/services/global";

  let global = new GlobalServices();

  export default {
    mixins: [editMixin],
    data() {
      return {
        isDisabled: false,
        dropdowns: {
          uxCategory: [],
          selectedCategory: null,
          uxUnitType: [],
          selectedUnitType: null,
          uxBinLocation: [],
          selectedBinLocation: null,
          uxWarehouse: [],
          selectedWarehouse: null,
          uxSupplier: [],
          selectedSupplier1: null,
          selectedSupplier2: null,
        },
      };
    },
    created() {
      this.MODULE = MODULE;
    },
    mounted() {
      this.initialized();
    },
    methods: {
      async initialized() {
        this.dropdowns.uxCategory = (await global.getUXDropDown(MODULE.INVENTORY.UX.InventoryCategory)) || [];
        this.dropdowns.uxUnitType = (await global.getUXDropDown(MODULE.INVENTORY.UX.InventoryUnitType)) || [];
        this.dropdowns.uxBinLocation = (await global.getUXDropDown(MODULE.INVENTORY.UX.InventoryLocation)) || [];
        this.dropdowns.uxWarehouse = (await global.getUXDropDown(MODULE.INVENTORY.UX.InventoryWarehouse)) || [];
        this.dropdowns.uxSupplier = (await global.getUXDropDown(MODULE.SUPPLIER.ActionId)) || [];
        if (this.modalObj.details) {
          this.setSelectedDropdownValues();
        }
      },
      getPreviousDropdownValue(dropDownList, id) {
        let previousDropdownSelection = null;

        if (id) {
          // set the obj of dropdown using `id`
          previousDropdownSelection = dropDownList.find((t) => {
            return t.id === id;
          });
        }

        return previousDropdownSelection && previousDropdownSelection.displayName ? previousDropdownSelection.displayName : "";
      },
      async setSelectedDropdownValues() {
        //Set Category
        this.dropdowns.selectedCategory =
          this.dropdowns.uxCategory.find((t) => {
            return t.id === this.modalObj.details.IntInventoryCategoryID;
          }) || null;

        //Set unit Type
        this.dropdowns.selectedUnitType =
          this.dropdowns.uxUnitType.find((t) => {
            return t.id === this.modalObj.details.IntUnitMeasurementID;
          }) || null;

        //Set Bin Location
        this.dropdowns.selectedBinLocation =
          this.dropdowns.uxBinLocation.find((t) => {
            return t.id === this.modalObj.details.IntInventoryLocationID;
          }) || null;
        //Set Warehouse
        this.dropdowns.selectedWarehouse =
          this.dropdowns.uxWarehouse.find((t) => {
            return t.id === this.modalObj.details.IntInventoryWarehouseID;
          }) || null;

        //Set Supplier 1
        this.dropdowns.selectedSupplier1 =
          this.dropdowns.uxSupplier.find((t) => {
            return t.id === this.modalObj.details.IntPrimarySupplierID;
          }) || null;

        //Set Supplier 2
        this.dropdowns.selectedSupplier2 =
          this.dropdowns.uxSupplier.find((t) => {
            return t.id === this.modalObj.details.IntSecondarySupplierID;
          }) || null;
      },
    },
  };
