import { render, staticRenderFns } from "./generic-table.vue?vue&type=template&id=6be829da&scoped=true"
import script from "./generic-table.vue?vue&type=script&lang=js"
export * from "./generic-table.vue?vue&type=script&lang=js"
import style0 from "./generic-table.vue?vue&type=style&index=0&id=6be829da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be829da",
  null
  
)

export default component.exports