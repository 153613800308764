
import Vue from "vue";

export default {
  name: "PurchaseOrderDetails",
  data() {
    return {
      modalStatus: {
        hideShowModal: false,
        modalName: "",
        modalTitle: ""
      }
    };
  },
  methods: {
    showModal(paramModalName, modalTitle) {
      this.modalStatus.hideShowModal = true;
      this.modalStatus.modalName = paramModalName;
      this.modalStatus.modalTitle = modalTitle;
      this.$store.dispatch("toggleModalStatus", this.modalStatus);
    }
  }
};
