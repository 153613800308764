
  export default {
    props: {
      dataObj: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {};
    },
    computed: {
      infoData() {
        return this.dataObj.info.data.filter((i) => i.isHighlightSection == false);
      },
      infoHighlightsData() {
        return this.dataObj.info.data.filter((i) => i.isHighlightSection == true);
      },
    },
    methods: {},
    components: {},
    mounted() {},
  };
