var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.FirstName),expression:"modalDataDetails.FirstName"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-add-new-contact-first-name"},domProps:{"value":(_vm.modalDataDetails.FirstName)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                'FirstName', //PropertyName
                _vm.modalDataDetails.FirstName, //Property Value
                'FirstName', //Display Name
                true
              )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "FirstName", $event.target.value)}}})]),_c('div',{staticClass:"col-lg-3 col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.LastName),expression:"modalDataDetails.LastName"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-contact-last-name","type":"text"},domProps:{"value":(_vm.modalDataDetails.LastName)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                'LastName', //PropertyName
                _vm.modalDataDetails.LastName, //Property Value
                'LastName', //Display Name
                true
              )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "LastName", $event.target.value)}}})]),_c('div',{staticClass:"col-lg-3 col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Position")]),_c('v-select',{attrs:{"options":_vm.uxPositionList,"id":"slidepanel-client-details-tabs-add-new-contact-position","reduce":(role) => role.id,"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.updateFieldSimplified(
                'IntRoleID', //PropertyName
                _vm.modalDataDetails.IntRoleID, //Property Value
                'Position', //Display Name
                true
              )}},model:{value:(_vm.modalDataDetails.IntRoleID),callback:function ($$v) {_vm.$set(_vm.modalDataDetails, "IntRoleID", $$v)},expression:"modalDataDetails.IntRoleID"}})],1),_c('div',{staticClass:"col-lg-3 col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Location")]),_c('v-select',{attrs:{"id":"slidepanel-client-details-tabs-add-new-contact-location","reduce":(location) => location.id,"options":_vm.uxLocationList,"label":"displayName","clearable":false},on:{"input":function($event){return _vm.updateFieldSimplified(
                'IntLocationId', //PropertyName
                _vm.modalDataDetails.IntLocationId, //Property Value
                'Location', //Display Name
                true
              )}},model:{value:(_vm.modalDataDetails.IntLocationId),callback:function ($$v) {_vm.$set(_vm.modalDataDetails, "IntLocationId", $$v)},expression:"modalDataDetails.IntLocationId"}})],1),_c('div',{staticClass:"col-md-12 border-bottom u-mt-small u-mb-small u-height-2"})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Email),expression:"modalDataDetails.Email"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-add-new-contact-email"},domProps:{"value":(_vm.modalDataDetails.Email)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                'Email', //PropertyName
                _vm.modalDataDetails.Email, //Property Value
                'Contact Email', //Display Name
                true
              )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Email", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Mobile")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Mobile),expression:"modalDataDetails.Mobile"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-add-new-contact-mobile"},domProps:{"value":(_vm.modalDataDetails.Mobile)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Mobile", $event.target.value)},function($event){return _vm.formatPhoneNumber('Mobile')}],"keyup":function($event){return _vm.updateFieldSimplified(
                'Mobile', //PropertyName
                _vm.modalDataDetails.Mobile, //Property Value
                'Mobile Phone', //Display Name
                true
              )}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Work")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Phone),expression:"modalDataDetails.Phone"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-add-new-contact-work"},domProps:{"value":(_vm.modalDataDetails.Phone)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Phone", $event.target.value)},function($event){return _vm.formatPhoneNumber('Phone')}],"keyup":function($event){return _vm.updateFieldSimplified(
                'Phone', //PropertyName
                _vm.modalDataDetails.Phone, //Property Value
                'Work Phone', //Display Name
                true
              )}}})]),_c('div',{staticClass:"col-md-12 my-2"},[_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"id":"chkAutoCCEmailOnQuote","name":"chkAutoCCEmailOnQuote"},on:{"change":function($event){return _vm.checkboxChanged(
                'AutoCCEmailOnQuote', //PropertyName
                !_vm.modalDataDetails.AutoCCEmailOnQuote, //Property Value
                'Copy contact on every quote email', //Display Name
                true
              )}},model:{value:(_vm.modalDataDetails.AutoCCEmailOnQuote),callback:function ($$v) {_vm.$set(_vm.modalDataDetails, "AutoCCEmailOnQuote", $$v)},expression:"modalDataDetails.AutoCCEmailOnQuote"}},[_c('span',{staticClass:"ml-2"},[_vm._v("Copy contact on every quote email")])]),_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"id":"chkAutoCCEmailOnInvoice","name":"chkAutoCCEmailOnInvoice"},on:{"change":function($event){return _vm.checkboxChanged(
                'AutoCCEmailOnInvoice', //PropertyName
                !_vm.modalDataDetails.AutoCCEmailOnInvoice, //Property Value
                'Copy contact on every invoice email', //Display Name
                true
              )}},model:{value:(_vm.modalDataDetails.AutoCCEmailOnInvoice),callback:function ($$v) {_vm.$set(_vm.modalDataDetails, "AutoCCEmailOnInvoice", $$v)},expression:"modalDataDetails.AutoCCEmailOnInvoice"}},[_c('span',{staticClass:"ml-2"},[_vm._v("Copy contact on every invoice email")])]),_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"id":"chkAutoCCEmailOnProjects","name":"chkAutoCCEmailOnProjects"},on:{"change":function($event){return _vm.checkboxChanged(
                'AutoCCEmailOnProjects', //PropertyName
                !_vm.modalDataDetails.AutoCCEmailOnProjects, //Property Value
                'Copy contact on every Job/Work Order email', //Display Name
                true
              )}},model:{value:(_vm.modalDataDetails.AutoCCEmailOnProjects),callback:function ($$v) {_vm.$set(_vm.modalDataDetails, "AutoCCEmailOnProjects", $$v)},expression:"modalDataDetails.AutoCCEmailOnProjects"}},[_c('span',{staticClass:"ml-2"},[_vm._v("Copy contact on every Job/Work Order email")])])],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Address")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.AddressLn1),expression:"modalDataDetails.AddressLn1"}],staticClass:"form-control",staticStyle:{"padding-bottom":"12px"},attrs:{"id":"slidepanel-client-details-tabs-add-new-contact-address","type":"text","rows":"4","placeholder":""},domProps:{"value":(_vm.modalDataDetails.AddressLn1)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                'AddressLn1', //PropertyName
                _vm.modalDataDetails.AddressLn1, //Property Value
                'Address', //Display Name
                true
              )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "AddressLn1", $event.target.value)}}})]),_c('div',{staticClass:"col-md-12 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Website/LinkedIn")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.WebAddress),expression:"modalDataDetails.WebAddress"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-client-details-tabs-add-new-contact-website-linkedin"},domProps:{"value":(_vm.modalDataDetails.WebAddress)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                'WebAddress', //PropertyName
                _vm.modalDataDetails.WebAddress, //Property Value
                'Website/LinkedIn', //Display Name
                true
              )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "WebAddress", $event.target.value)}}})])])]),_c('div',{staticClass:"col-md-12"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Notes/Comments")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Notes),expression:"modalDataDetails.Notes"}],staticClass:"form-control",attrs:{"type":"text","rows":"6"},domProps:{"value":(_vm.modalDataDetails.Notes)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
            'Notes', //PropertyName
            _vm.modalDataDetails.Notes, //Property Value
            'Notes', //Display Name
            true
          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Notes", $event.target.value)}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }