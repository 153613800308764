
  import Vue from "vue";
  import {DashboardFilter} from "@/interfaces/filters/DashboardFilters";
  import ProjectServices from "@/services/projects";
  import EVENTS from "@/constants/events";
  import ApplicationService from "@/services/application";
  import DashboardService from "@/services/dashboard";
  const app = new ApplicationService();
  const dashboardService = new DashboardService();
  export default Vue.extend({
    name: "TableHeaderAndRows",

    data() {
      return {
        EVENT_ACTION: {},
        filter: {} as DashboardFilter,
        sortStatusAssending: true,
        sortDueDateAssending: true,
        sortTechnicianStatusAssending: true,
        projectHoverInfo: "",
        modalStatus: {
          hideShowModal: false,
          modalName: "",
          modalTitle: "",
          modalSaveButtonTitle: "",
          data: {},
        },
      };
    },
    watch: {
      // whenever question changes, this function will run
      async projects(projects, oldproject) {
        //If an id was specified in the url, then get the data and open the side panel
        if (this.$route.params.id) {
          let rowObj = this.projects.find((obj) => obj.intProjectID == this.$route.params.id);

          if (!rowObj) {
            //LookUp Related Project
            let filter = {} as any;

            filter.projectRefNumber = this.$route.params.id;
            let results = await dashboardService.getProjects(filter);
            if (results.resources.length > 0) {
              rowObj = results.resources.find((data) => parseInt(data.intProjectID) === parseInt(this.$route.params.id));
            }
          }

          this.showPanel(rowObj);
        }
      },
    },
    methods: {
      showModal(paramModalName, modalTitle, sendButtonText, data) {
        this.modalStatus.hideShowModal = true;
        this.modalStatus.modalName = paramModalName;
        this.modalStatus.modalTitle = modalTitle;
        this.modalStatus.modalSaveButtonTitle = sendButtonText;
        this.modalStatus.data = data;
        this.$store.dispatch("toggleModalStatus", this.modalStatus);
      },
      async getHoverInfo(projectid) {
        let api = new ProjectServices();

        this.projectHoverInfo = "";

        this.projectHoverInfo = await api.getProjectHoverSummary(projectid);

        //ONLY TRACK EVENTS IN PRODUCTION
        // if (process.env.VUE_APP_ENVIRONMENT === "production") {
        //   window["analytics"].track(this.EVENT_ACTION.DASHBOARD.Action.Hover, this.projectHoverInfo);
        // }

        if (this.projectHoverInfo === "") this.projectHoverInfo = "No description/notes are available.";

        return "tooltip_" + projectid;
      },
      onProjectSelectionChange(value, event) {
        if (event.target.checked) {
          this.$store.dispatch("addToSelectedProjects", value);
        } else {
          this.$store.dispatch("removeSelectedProjects", value);
        }
      },
      onDueDateSort() {
        if (this.sortDueDateAssending) {
          this.changeSortOrder("dueDateASC");
          this.sortDueDateAssending = false;
        } else {
          this.changeSortOrder("dueDateDESC");
          this.sortDueDateAssending = true;
        }
      },
      onTechnicianStatusSort() {
        if (this.sortTechnicianStatusAssending) {
          this.changeSortOrder("technicianASC");
          this.sortTechnicianStatusAssending = false;
        } else {
          this.changeSortOrder("technicianDESC");
          this.sortTechnicianStatusAssending = true;
        }
      },
      onStatusSort() {
        if (this.sortStatusAssending) {
          this.changeSortOrder("statusASC");
          this.sortStatusAssending = false;
        } else {
          this.changeSortOrder("statusDESC");
          this.sortStatusAssending = true;
        }
      },
      changeSortOrder(sortOrder: string) {
        this.$store.dispatch("changeSortOrder", sortOrder);
      },
      showPanel(data) {
        if (data) {
          //Set Id of the currently selected/edit table item
          let currentPath = this.$route.path + "/" + data.intProjectID;

          let route = {name: "projects-details", params: {id: data.intProjectID}};

          //Update the router
          if (currentPath !== this.$route.path) {
            this.$router.push({name: `${route.name}`, params: {id: `${data.intProjectID}`}});
          }

          app.showSidePanel(null, data, route);
        }
      },
    },

    computed: {
      projects() {
        return this.$store.getters.getProjects;
      },
    },
    async mounted() {
      let tracking = {
        ModuleName: "DASHBOARD", //"PROJECTS",
        ModuleAction: EVENTS.DASHBOARD.View, //EVENTS.PROJECTS.View,
        Description: "Viewed Dashboard", //"Viewed Project",
        Id: 0, //this.data.intProjectID ,         // data.intProjectID
      };

      app.trackAnalytics(tracking);
    },
    async created() {
      this.EVENT_ACTION = EVENTS;
    },
  });
