import axios from "axios";
import EditDataObject from "@/interfaces/EditDataObject";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";
import UtilityString from "@/utilities/strings";
let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class QuoteServices {
  auth = AuthService.getInstance();
  authQueryString = "";
  utility = new UtilityString();

  constructor() {
    axios.defaults.baseURL = apiUrl;

    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

async getQuoteById(id = null) {
    // Set the base URL for Axios requests
    axios.defaults.baseURL = apiUrl;

    // If `id` is null, use the route to get the `qid` from the URL
    const quoteId = id || Router.currentRoute.query.qid;

    // Construct the query string with the required parameters
    const queryString = `${this.auth.getQueryString()}&id=${quoteId}`;

    // Make the API request to fetch the quote by ID
    const dataResults = await axios.get(`quote/get${queryString}`, apiHeaderObj);

    // Return the resources data from the response
    return dataResults.data.resources;
}

  async getQuoteTermsById(id: Number) {
    axios.defaults.baseURL = apiUrl;

    let queryString = this.auth.getQueryString() + "&id=" + id;

    let dataResults = await axios.get("quote/terms" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async getQuoteProjectById(id: Number) {
    axios.defaults.baseURL = apiUrl;

    let queryString = this.auth.getQueryString() + "&id=" + id;

    let dataResults = await axios.get("quote/project/get" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async getQuoteDetails(id: Number) {
    axios.defaults.baseURL = apiUrl;

    //TOD DO: change quote id to qid instead of quoteid, need to also change project ear
    let queryString = this.auth.getQueryString() + "&qid=" + id;

    let dataResults = await axios.get("quote/details" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async updateData(quoteItem) {
    this.setAPIDefaults(quoteItem);

    let quoteItemResult = null;

    if (quoteItem.quoteDetailId === 0) quoteItemResult = await axios.post("quote/details/post", quoteItem, apiHeaderObj).catch(function(error) {});
    else quoteItemResult = await axios.put("quote/details/put", quoteItem, apiHeaderObj).catch(function(error) {});
    return quoteItemResult;
  }

  private setAPIDefaults(quoteItem: any) {
    axios.defaults.baseURL = apiUrl;

    let auth = AuthService.getInstance();
    quoteItem = Object.assign(quoteItem, auth.getAuthObject());
    quoteItem.correlationID = this.utility.generateGUID();
  }

  async deleteItem(quoteItem) {
    this.setAPIDefaults(quoteItem);

    let quoteItemResult = null;

    quoteItemResult = await axios.post("quote/details/delete", quoteItem, apiHeaderObj).catch(function(error) {});

    return quoteItemResult;
  }
}

export default QuoteServices;
