<template>
  <div>
    <ValidationObserver ref="modalForm">
      <b-modal
        :id="currentComponent"
        v-model="hideShowModal"
        :size="currentComponent === 'UpdateLocationProject' || currentComponent === 'DuplicateInvoice' ? 'md' : 'xl'"
        scrollable
        centered
        :modal-class="[currentComponent === 'ExpandEquipmentList' ? 'modal-full-width' : '']"
        :body-class="[{customFooterModalScrollFix: currentComponent === 'NewInvoice' || currentComponent === 'NewEmail'}, `p-0 scrollbar scrollable-div modal-height ${bodyClass}`]"
        :header-class="[currentComponent === 'PDFViewer' ? 'border-0' : '']"
        :footer-class="[currentComponent === 'RecordSinglePayment' || currentComponent === 'NewInvoice' || currentComponent === 'NewEmail' ? 'd-none' : '']"
        @close="onCancel"
      >
        <!-- Header -->
        <template v-slot:modal-title>
          <div>
            <h4 class="font-light">{{ modalTitle }}</h4>
          </div>
        </template>

        <!-- Body -->
        <div :class="['d-block', {'h-100': currentComponent === 'PDFViewer' || 'RecordPayment'}]">
          <!-- Modal's top info box -->
          <!-- <modalTopInfoBox></modalTopInfoBox> -->

          <div :class="[currentComponent === 'PDFViewer' ? 'p-0 h-100' : 'p-3', currentComponent === 'RecordPayment' ? 'h-100' : '']">
            <div v-show="!success.show" :class="[currentComponent === 'PDFViewer' ? 'h-100' : '']">
              <b-alert dismissible fade class="mb-3 z-index-5" v-model="alertNotification.isVisible" :variant="alertNotification.type">
                <i class="fa fa-check-circle mr-2"></i>
                <span class="sub-title mr-2" :id="'alert-notification-' + currentComponent + '-' + alertNotification.type">
                  {{ alertNotification.message }}
                </span>
              </b-alert>
              <component v-bind:is="currentComponent"></component>
            </div>
            <SuccessScreen v-if="success.show" :amount="0" :main-text="success.text" :enabled-new-transaction="true" @transaction="handleAnotherTransaction" />
          </div>
        </div>

        <!-- Footer -->
        <template v-slot:modal-footer>
          <div class="w-100 test">
            <div v-if="!hideBottomBar">
              <b-button size="md" class="mr-2 border-0" @click="backToProjectView" v-if="view !== 'newProjectView'"> <i class="fa fa-chevron-left u-mr-xsmall"></i>Back </b-button>

              <!-- Save/Send Button -->
              <b-button
                size="md"
                v-if="!isSaveButtonHidden"
                @click="onSubmit"
                :disabled="isSaveButtonDisabled"
                class="ss-primary-bg mr-2 border-0"
                :id="`dashboard-top-nav-modal-${modalTitleAsID}-save-button`"
              >
                <!-- Normal State -->
                <i v-if="!isSaveButtonDisabled" class="fa fa-save u-mr-xsmall"></i>
                <span v-if="!isSaveButtonDisabled">
                  {{ saveButtonTitle }}
                </span>

                <!-- Processing State -->
                <img v-if="isSaveButtonDisabled" src="@/assets/images/loading-spinner.gif" class="loading" alt="Loading..." />
                <span v-if="isSaveButtonDisabled && !isDocumentLoading">&nbsp;Processing...</span>
                <span v-if="isSaveButtonDisabled && isDocumentLoading">&nbsp;Generating Document...</span>
              </b-button>

              <!-- Cancel Button -->
              <b-button
                size="md"
                class="ss-info-bg border-0"
                @click="onCancel"
                :disabled="isSaveButtonDisabled"
                v-if="view === 'newProjectView'"
                :id="`dashboard-top-nav-modal-${modalTitleAsID}-cancel-button`"
              >
                <i class="fa fa-times u-mr-xsmall"></i>Close
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import EVENTS from "@/constants/events";

  import * as components from "@/components";
  import modalTopInfoBox from "@/components/_universal/modal-top-info-box";
  import SuccessScreen from "@/components/_universal/global/success-screen.vue";
  import _ from "lodash";

  export default {
    data() {
      return {
        EVENT_ACTION: {},
        isSaveButtonDisabled: false,
        modalWithIframe: false,
        isModalWithIframePrint: false,
        hideBottomBar: false,
        isSaveButtonHidden: false,
        view: "newProjectView",
        hideShowModal: false,
        currentComponent: "NewProject",
        modalTitle: "",
        modalSaveButtonTitle: "",
        saveEvent: "Save", //Event name to keep track of when modal SAVE button is pressed. This property is dynamically set depending on modal component name
        closeEvent: "Close", //Event name to keep track of when modal CLOSE button is pressed. This property is dynamically set depending on modal component name
        successEvent: "show-success", //Event name to keep track of when modal CLOSE button is pressed. This property is dynamically set depending on modal component name
        alertNotification: {
          type: "danger",
          message: "Succesfully",
          isVisible: false,
        },
        //modalStatus: {},
        isDocumentLoading: false,
        success: {
          show: false,
          text: "",
        },
      };
    },
    methods: {
      setButtonState(isLoading) {
        this.isDocumentLoading = isLoading;
        this.isSaveButtonDisabled = isLoading;
      },
      handleAnotherTransaction() {
        this.success.show = false;
        this.isSaveButtonHidden = false;
      },
      //Hide/Show message to user
      setAlertNotification(alertObj) {
        let message = "";
        let appendMessage = "";
        let componentMessage = "";
        this.alertNotification = {
          type: "success",
          message: "",
          isVisible: false,
        };

        if (alertObj) {
          if (alertObj.type === "success") {
            appendMessage = " You may close this window.";

            let data = this.$store.getters.getModalStatus.data;

            if (data.id) {
              if (this.modalTitle) {
                componentMessage = this.modalTitle
                  .replace("new", "")
                  .replace("add", "saved")
                  .replace("edit", "saved");
              }
            } else {
              if (this.modalTitle) {
                componentMessage = this.modalTitle.replace("add", "added").replace("edit", "edited");
              }
            }

            message = alertObj.message + componentMessage + "." + appendMessage;
          } else if (alertObj.type === "danger") {
            appendMessage = " Please contact support for further assistance.";

            if (data.id) {
              if (this.modalTitle)
                componentMessage = this.modalTitle
                  .replace("new", "")
                  .replace("add", "saved")
                  .replace("edit", "saved");
            } else {
              if (this.modalTitle) componentMessage = this.modalTitle.replace("add", "added").replace("edit", "edited");
            }

            message = alertObj.message + componentMessage + "." + appendMessage;
          }

          if (alertObj.type === "danger") {
            alertObj.message = message;

            this.alertNotification = {
              type: alertObj.type,
              message: message,
              isVisible: true,
              id: "test",
            };
          }
        }

        setTimeout(() => {
          alertObj = {
            type: "success",
            message: "",
            isVisible: false,
          };
          this.alertNotification = {
            type: "success",
            message: "",
            isVisible: false,
          };
          message = "";
          appendMessage = "";
          componentMessage = "";
        }, 8000);

        message = "";
        appendMessage = "";
        componentMessage = "";
      },

      //Initialize modal state
      setModalState(state, componentName) {
        this.currentComponent = componentName; //Name of the component to load
        this.modalStatus = state.Dashboard.modalStatus;
        //Subscribe to save/cancel button click event on modal box
        this.saveEvent = "Save" + this.currentComponent;
        this.closeEvent = "Close" + this.currentComponent;
        this.alertEvent = "AlertNotification" + this.currentComponent;
        this.successEvent = "show-success" + this.currentComponent;

        this.hideShowModal = state.Dashboard.modalStatus.hideShowModal; //Determins if the modal is open or closed
        this.isSaveButtonHidden = state.Dashboard.modalStatus.isSaveButtonHidden; //In some cases we need to hide the save buttons (e.g. if it's an iframe being loaded)
        this.modalTitle = state.Dashboard.modalStatus.modalTitle; //Set the modal display tital at the top of the window
        this.modalSaveButtonTitle = state.Dashboard.modalStatus.modalSaveButtonTitle; //Sometimes we need to change the modal save button text.. et.. Edit, Save, Update etc..

        if (componentName === this.EVENT_ACTION.DOCUMENTS_PHOTOS.New) {
          this.isSaveButtonHidden = true;
        }
        //Subscribe to Alert Notifications
        this.$root.$on(this.alertEvent, this.setAlertNotification);
      },
      refreshModal(state) {
        //Get the current component to be loaded
        let componentName = state.Dashboard.modalStatus.modalName;

        //If any of the modal has Iframe in the name, load the standard modal Iframe component
        if (this.currentComponent.indexOf("ViewIframeModal") !== -1) {
          this.currentComponent = "ViewIframeModal";
          this.hideBottomBar = true;
        }

        if (state) {
          //Set Modal properties
          this.setModalState(state, componentName);
        }
      },
      onSwitchView(screenChange) {
        this.view = screenChange;
      },
      backToProjectView() {
        this.view = "newProjectView";
      },
      onCancel() {
        if (this.isSaveButtonDisabled) return;
        //Close modal box.
        this.hideShowModal = false;
        this.currentComponent = "";
        this.success = {
          show: false,
          text: "",
        };
        //Broadcast to any component that care, that the modal was closed
        this.$root.$emit(this.closeEvent);
      },
      onSubmit() {
        // prevent double sending when being saved
        if (this.isSaveButtonDisabled) return;
        //Validate Modal Form
        this.$refs.modalForm.validate().then(async (success) => {
          if (!success) {
            return;
          }
          //Imediately disable save button to prevent double click
          this.isSaveButtonDisabled = true;

          this.$root.$emit(this.saveEvent, this.$store.getters.getModalStatus.data);

          // this resets form validation
          requestAnimationFrame(() => {
            this.$refs.modalForm.reset();
          });
          const componentsWithSuccessScreen = ["NewMaterial", "NewProjectEquipment", "NewProjectTask", "NewProjectTimeTracking", "NewContact"];
          if (_.intersection([this.currentComponent], componentsWithSuccessScreen).length === 0 || this.modalStatus.isInEditMode) {
            setTimeout(() => {
              this.isSaveButtonDisabled = false;
            }, 3000);
          }
        });
      },
    },
    computed: {
      ...mapState(["modalStatus"]),
      saveButtonTitle() {
        if (this.modalSaveButtonTitle) {
          return this.modalSaveButtonTitle;
        } else {
          return "Save";
        }
      },
      bodyClass() {
        if (this.modalWithIframe || this.currentComponent === "PDFViewer") {
          return "overflow-unset";
        } else if (this.currentComponent === "ImageViewer") {
          return "overflow-unset u-bg-black_lighter";
        } else if (this.currentComponent === "ViewModalDashboard" || this.currentComponent === "RecordPayment") {
          return "ss-light-bg";
        } else if (this.currentComponent === "UpdateLocationProject" || this.currentComponent === "DocumentFormEditor") {
          return "smallModalHeight";
        } else {
          return "scrollbar scrollable-div modal-height";
        }
      },
      modalTitleAsID() {
        let title = this.modalTitle;
        title = title.toLowerCase();
        return title
          .split(" ")
          .join("-")
          .replace("/", "-");
      },
    },
    mounted() {
      this.$root.$on("bv::modal::show", () => {
        this.success = {
          show: false,
          text: "",
        };
        this.isSaveButtonDisabled = false;
        this.$root.$on(this.successEvent, (data) => {
          this.isSaveButtonDisabled = false;
          this.success = {
            show: data.show,
            text: data.text,
          };
          this.isSaveButtonHidden = data.show;
        });
      });

      this.$root.$on("bv::modal::hide", () => {
        this.$root.$off(this.successEvent);
      });
    },
    created() {
      this.EVENT_ACTION = EVENTS;
      this.$root.$on("close-parent-modal", this.onCancel);
      this.$root.$on("toggle-save-button", (d = false) => {
        this.isSaveButtonHidden = d;
      });

      //Waits for a change on the setModalStatus Property and update component
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "setModalStatus") {
          let componentName = state.Dashboard.modalStatus.modalName;

          this.modalWithIframe = true;

          this.alertNotification.isVisible = false;

          //Special condition to hide Save and close buttons if in iframe
          if (componentName.indexOf("ViewedIframeModal") !== -1) {
            //If it's a Print Preview PDF iframe, disable scroll
            if (componentName === this.EVENT_ACTION.IFRAME.Print || componentName === this.EVENT_ACTION.IFRAME.PreviewImage) {
              this.isModalWithIframePrint = true;
            } else {
              this.isModalWithIframePrint = false;
            }

            componentName = "ViewedIframeModal";
            this.hideBottomBar = true;
          } else if (
            componentName === this.EVENT_ACTION.Image.View ||
            componentName === this.EVENT_ACTION.PDF.View ||
            componentName === this.EVENT_ACTION.PROJECTS.AssignTech ||
            componentName === this.EVENT_ACTION.SETTINGS.SpecificListing ||
            componentName === this.EVENT_ACTION.INVOICE.RecordSinglePayment
          ) {
            this.hideBottomBar = true;
            this.isModalWithIframePrint = false;
            this.modalWithIframe = false;
          } else {
            this.hideBottomBar = false;
            this.isModalWithIframePrint = false;
            this.modalWithIframe = false;
          }

          //Set Modal properties
          this.setModalState(state, componentName);
        }
      });
    },
    beforeDestroy() {
      //Unscribe from save button click event on modal box
      this.$root.$off(this.saveEvent);
      this.$root.$off(this.closeEvent);
      this.$root.$off(this.alertEvent);
      this.$root.$off("close-parent-modal");
      this.$root.$off("toggle-save-button");

      this.alertNotification = {
        type: "success",
        message: "",
        isVisible: false,
      };
    },
    components: {
      ...components,
      modalTopInfoBox,
      SuccessScreen,
    },
  };
</script>
