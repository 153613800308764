
  import Vue from "vue";
  import DashboardService from "@/services/dashboard";
  import ProjectServices from "@/services/projects";
  import EVENTS from "@/constants/events";

  const api = new ProjectServices();

  export default Vue.extend({
    data() {
      return {
        EVENT_ACTION: {},
        isSuccesful: false,
        isError: false,
        dispatchMessage: "",
        dispatchDate: new Date().toDateString(),
        selectedTechnician: [],
        technicians: [],
        selectedTechnicianId: [],
        project: null,
        modalStatus: {
          hideShowModal: false,
          modalName: "",
          modalTitle: "",
          message: "",
          modalSaveButtonTitle: "",
          data: {},
        },
      };
    },
    computed: {
      technicianList() {
        return this.technicians;
      },
    },
    watch: {
      dispatchMessage: {
        handler(val) {
          this.$store.dispatch("updateModalMessage", val);
        },
      },
    },
    methods: {
      async composeMessage() {
        let message = await api.getDispatchMessage(this.modalStatus.data.intProjectID);

        return message;
      },
      onTechnicianSelected() {},

      //This method subscribes to the modal submit button event
      async onDispatch(data) {
        let dashboardService = new DashboardService();

        if (this.selectedTechnician != null && data != null) {
          let technicianId = this.selectedTechnician.id;
          let projectsList = data.intProjectID;
          let message = this.modalStatus.message;

          if (message.length > 0) {
            let displayDispatchResponse = await dashboardService.dispatchTechnicians(technicianId, this.dispatchDate, projectsList, message);

            if (displayDispatchResponse) {
              if (displayDispatchResponse.status == 200) {
                this.isSuccesful = true;
                this.isError = false;

                setTimeout(() => {
                  this.isSuccesful = false;
                }, 30000);
              } else {
                this.isSuccesful = false;
                this.isError = true;
              }
            } else {
              this.isSuccesful = false;
              this.isError = true;
            }
          }
        }
      },

      //Initialize Data
      async loadData() {
        this.modalStatus = this.$store.getters.getModalStatus;
        this.technicians = this.$store.getters.getTechnicianDropDown;
        this.dispatchMessage = await this.composeMessage();
      },
    },
    async mounted() {
      await this.loadData();
    },
    created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.DISPATCH.New, this.onDispatch);
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.DISPATCH.New, this.onDispatch);
    },
  });
