var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-tabs',{staticClass:"position-relative",staticStyle:{"top":"-15px"},attrs:{"content-class":"mt-3"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":"Basic","active":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Site/Store/Location Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.StoreName),expression:"modalDataDetails.StoreName"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-store-name","type":"text"},domProps:{"value":(_vm.modalDataDetails.StoreName)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                    'StoreName', //PropertyName
                    _vm.modalDataDetails.StoreName, //Property Value
                    'StoreName', //Display Name
                    true
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "StoreName", $event.target.value)}}})]),_c('div',{staticClass:"col-md-4 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Override Client Billing Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.OverideBillingName),expression:"modalDataDetails.OverideBillingName"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-overrride-client-billing-name","type":"text"},domProps:{"value":(_vm.modalDataDetails.OverideBillingName)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                    'OverideBillingName', //PropertyName
                    _vm.modalDataDetails.OverideBillingName, //Property Value
                    'Overide BillingName', //Display Name
                    true
)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "OverideBillingName", $event.target.value)}}})]),_c('div',{staticClass:"col-md-4 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Primary Contact")]),(_vm.modalObj.isInEditMode)?_c('v-select',{attrs:{"options":_vm.modalUXData.uxContacts,"id":"slidepanel-client-details-tabs-add-new-location-primary-contact","value":this.getSelectedDropDownValue('uxContacts', 'IntContactID', true),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
                    $event,
                    'Contact', //Display Name
                    'uxContacts', //Dropdown List Object
                    'IntContactID', //Edit Details Property Name
                    true
                  )}}}):_c('v-select',{attrs:{"options":_vm.modalUXData.uxContacts,"reduce":(location) => location.id,"id":"slidepanel-client-details-tabs-add-new-location-primary-contact","label":"displayName"},model:{value:(_vm.modalDataDetails.IntContactID),callback:function ($$v) {_vm.$set(_vm.modalDataDetails, "IntContactID", $$v)},expression:"modalDataDetails.IntContactID"}})],1),_c('div',{staticClass:"col-md-12 border-bottom u-mt-small u-mb-small u-height-2"})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Address")]),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Address),expression:"modalDataDetails.Address"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-address","type":"text"},domProps:{"value":(_vm.modalDataDetails.Address)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                      'Address', //PropertyName
                      _vm.modalDataDetails.Address, //Property Value
                      'Address', //Display Name
                      true
                    )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Address", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(v.errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("City")]),_c('ValidationProvider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.City),expression:"modalDataDetails.City"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-stor-city","type":"text"},domProps:{"value":(_vm.modalDataDetails.City)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                    'City', //PropertyName
                    _vm.modalDataDetails.City, //Property Value
                    'City', //Display Name
                    true
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "City", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(v.errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("State/Province")]),_c('ValidationProvider',{attrs:{"name":"state/province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Province),expression:"modalDataDetails.Province"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-state-province","type":"text"},domProps:{"value":(_vm.modalDataDetails.Province)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                    'Province', //PropertyName
                    _vm.modalDataDetails.Province, //Property Value
                    'Province', //Display Name
                    true
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Province", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(v.errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Zip/Postal Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.PostalCode),expression:"modalDataDetails.PostalCode"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-zip-code","type":"text"},domProps:{"value":(_vm.modalDataDetails.PostalCode)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                  'PostalCode', //PropertyName
                  _vm.modalDataDetails.PostalCode, //Property Value
                  'Zip/Postal Code', //Display Name
                  true
                )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "PostalCode", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Country")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Country),expression:"modalDataDetails.Country"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-country","type":"text"},domProps:{"value":(_vm.modalDataDetails.Country)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                  'Country', //PropertyName
                  _vm.modalDataDetails.Country, //Property Value
                  'Country', //Display Name
                  true
                )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Country", $event.target.value)}}})])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Overide Client Billing Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.OverideBillingEmailAddress),expression:"modalDataDetails.OverideBillingEmailAddress"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-override-client-billing-email","type":"text"},domProps:{"value":(_vm.modalDataDetails.OverideBillingEmailAddress)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                  'OverideBillingEmailAddress', //PropertyName
                  _vm.modalDataDetails.OverideBillingEmailAddress, //Property Value
                  'Overide Billing Email Address', //Display Name
                  true
                )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "OverideBillingEmailAddress", $event.target.value)}}})]),_c('div',{staticClass:"col-md-12 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Email),expression:"modalDataDetails.Email"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-email","type":"text"},domProps:{"value":(_vm.modalDataDetails.Email)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                  'Email', //PropertyName
                  _vm.modalDataDetails.Email, //Property Value
                  'Location Email', //Display Name
                  true
                )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Email", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Phone")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Phone),expression:"modalDataDetails.Phone"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-phone","type":"text"},domProps:{"value":(_vm.modalDataDetails.Phone)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Phone", $event.target.value)},function($event){return _vm.formatPhoneNumber('Phone')}],"keyup":function($event){return _vm.updateFieldSimplified(
                    'Phone', //PropertyName
                    _vm.modalDataDetails.Phone, //Property Value
                    'Location Phone', //Display Name
                    true
                  )}}})]),_c('div',{staticClass:"col-md-6 u-mb-xsmall"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Fax")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Fax),expression:"modalDataDetails.Fax"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-fax","type":"text"},domProps:{"value":(_vm.modalDataDetails.Fax)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Fax", $event.target.value)},function($event){return _vm.formatPhoneNumber('Fax')}],"keyup":function($event){return _vm.updateFieldSimplified(
                    'Fax', //PropertyName
                    _vm.modalDataDetails.Fax, //Property Value
                    'Location Fax', //Display Name
                    true
                  )}}})])])]),_c('div',{staticClass:"col-md-12"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Location Specific Notes")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Notes),expression:"modalDataDetails.Notes"}],staticClass:"form-control",attrs:{"id":"slidepanel-client-details-tabs-add-new-location-specific-notes","type":"text","rows":"6"},domProps:{"value":(_vm.modalDataDetails.Notes)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
              'Notes', //PropertyName
              _vm.modalDataDetails.Notes, //Property Value
              'Location npNotes', //Display Name
              true
            )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalDataDetails, "Notes", $event.target.value)}}})])])]),(_vm.modalObj.isInEditMode)?_c('b-tab',{attrs:{"title":"Equipment"}},_vm._l((_vm.equipmentListTab),function(eq){return _c('div',{key:eq.id,staticClass:"c-choice c-choice--checkbox u-mr-small mb-0 border-bottom py-3"},[_c('input',{staticClass:"c-choice__input",attrs:{"id":eq.id,"name":"checkboxes","type":"checkbox"}}),_c('label',{staticClass:"c-choice__label align-items-start mb-0",attrs:{"for":eq.id}},[_c('div',{staticClass:"position-relative",staticStyle:{"top":"-5px"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(eq.name))]),_c('span',[_c('strong',[_vm._v(_vm._s(eq.modelNumber))]),_vm._v(" - "+_vm._s(eq.locationOnSite))])])])])}),0):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }