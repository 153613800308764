
  import Vue from "vue";
  import QuoteServices from "@/services/quotes";
  import TemplateServices from "@/services/templates";
  import EditDataObject from "@/interfaces/EditDataObject";
  import "vue-simple-suggest/dist/styles.css";
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  import editMixin from "@/mixin/edit-mixin";

  /// <summary>
  /// QuoteDetails component
  /// Only one panel open at a time using activePanel
  /// Debounce update logic is preserved.
  /// </summary>
  const quoteApi = new QuoteServices();
  const global = new GlobalServices();

  export default Vue.extend({
    name: "QuoteDetails",
    mixins: [editMixin],
    props: {
      dataObj: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        /// <summary>
        /// Single variable that stores which panel is open
        /// e.g. "title", "subtitle", "summary", ...
        /// </summary>
        activePanel: "" as string,

        /// <summary>
        /// The object holding your text fields
        /// Properly cased to match your JSON
        /// </summary>
        quoteDetails: {
          title: "",
          subtitle: "",
          summary: "",
          description: "",
          contentSection1: "",
          contentSection2: "",
          contentSection3: "",
          requireDepositAdvanceLabel: "",
          customText: "",
          comments: ""
        },

        /// <summary>
        /// Show error warning if needed
        /// </summary>
        showErrorWarning: false,

        /// <summary>
        /// Example numeric field if needed
        /// </summary>
        subTotal: 0,

        /// <summary>
        /// Template list from server
        /// </summary>
        uxTemplateList: [] as any[],

        /// <summary>
        /// Timer used for 500ms debounce
        /// </summary>
        timer: null as any, // or 'null as number | null'

        /// <summary>
        /// For referencing a selected template item, if needed
        /// </summary>
        selectedItem: {} as any,

        /// <summary>
        /// Example Froala config
        /// </summary>
        config: {
          toolbarButtons: [
            ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript"],
            ["fontFamily", "fontSize", "textColor", "backgroundColor"],
            ["inlineClass", "inlineStyle", "clearFormatting"]
          ],
          height: 600,
          events: {
            "froalaEditor.initialized": function() {}
          },
          key: "YNB3fJ3B8C10D6B5D2A-9rlvqgkD6zdjI-8I-7B-22fdtB1zH-9iB3B9B6D5C2C4D3H3G3H3==",
          attribution: false
        }
      };
    },
    methods: {
      /// <summary>
      /// Toggle the clicked panel. Only one can be open at a time.
      /// If the same panel is clicked, close it; otherwise open the new one.
      /// </summary>
      /// <param name="panelName">The name of the panel to open</param>
      togglePanel(panelName: string): void {
        if (this.activePanel === panelName) {
          // If user clicked the same panel, close it
          this.activePanel = "";
        } else {
          // Otherwise, open the requested panel
          this.activePanel = panelName;
        }
      },

      /// <summary>
      /// Debounce post data to server after 500ms
      /// </summary>
      /// <param name="dataObj">The data object to send</param>
      async updateData(dataObj: EditDataObject): Promise<void> {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          global.postData(dataObj);
        }, 500);
      },

      /// <summary>
      /// Update a field in the quoteDetails object
      /// </summary>
      /// <param name="propertyName">Name of the property</param>
      /// <param name="propertyValue">Value of the property</param>
      /// <param name="displayName">Friendly label for UI/logs</param>
      updateField(propertyName: string, propertyValue: any, displayName: string): void {
        const editObj = {} as EditDataObject;

        /// Using your existing approach with dataObj.data.id
        editObj.id = this.dataObj.data.id;
        editObj.propertyName = propertyName;
        editObj.propertyValue = propertyValue;
        editObj.displayName = displayName;
        editObj.actionId = MODULE.QUOTATION.ActionId;

        // If spelled "qutoteNumber" in your JSON, keep it that way
        editObj.referenceNumber = this.dataObj.data.qutoteNumber;

        this.updateData(editObj);
      },

      /// <summary>
      /// Fetch initial data from server
      /// </summary>
      async initializeData(): Promise<void> {
        this.uxTemplateList = await global.getUXDropDown(
          MODULE.TEMPLATES.ActionId,
          MODULE.QUOTATION.ModuleId,
          MODULE.QUOTATION.TemplateTypeId
        );

        const id = this.dataObj.data.id;
        const quoteFromServer = await quoteApi.getQuoteById(id);

        // Map your fields from the server to your data model
        this.quoteDetails.title = quoteFromServer.title || "";
        this.quoteDetails.subtitle = quoteFromServer.subtitle || "";
        this.quoteDetails.summary = quoteFromServer.summary || "";
        this.quoteDetails.description = quoteFromServer.description || "";
        this.quoteDetails.contentSection1 = quoteFromServer.contentSection1 || "";
        this.quoteDetails.contentSection2 = quoteFromServer.contentSection2 || "";
        this.quoteDetails.contentSection3 = quoteFromServer.contentSection3 || "";
        // Boolean or text for requireDepositAdvanceLabel
        this.quoteDetails.requireDepositAdvanceLabel = quoteFromServer.requireDepositAdvanceLabel
        
        this.quoteDetails.customText = quoteFromServer.customText || "";
        this.quoteDetails.comments = quoteFromServer.comments || "";

        if (!this.quoteDetails.description) {
          this.selectedItem = this.uxTemplateList.find((item: any) => item.isDefault === true);
        }
      }
    },
    async mounted(): Promise<void> {
      // Additional mount logic if needed
    },
    async created(): Promise<void> {
      this.initializeData();
    }
  });
