var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-2 mb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"}),_c('div',{staticClass:"col-lg-6 col-md-12 pr-lg-1 mb-lg-0 mb-2"},[_c('b-card',{staticClass:"details"},[_c('div',{staticClass:"header mb-3 d-flex justify-content-between"},[_c('h5',[_vm._v("Item Information")]),_c('i',{staticClass:"fa fa-info-circle info-icon text-secondary",attrs:{"id":"basicinfo"}},[_c('b-tooltip',{attrs:{"target":"basicinfo","triggers":"hover","placement":"righttop"}},[_c('div',[_vm._v("Inventory Details")]),_c('p',[_vm._v("Enter inventory item, equipment or any product")])])],1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Name),expression:"currentDataObj.details.Name"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-name","type":"text"},domProps:{"value":(_vm.currentDataObj.details.Name)},on:{"keyup":function($event){return _vm.updateField(
                    'Name', //PropertyName
                    _vm.currentDataObj.details.Name, //Property Value
                    'Item Name', //Display Name,
                    _vm.currentDataObj.details.Name, //Display Value
                    'name' //Data object Property Name
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "Name", $event.target.value)}}})]),_c('div',{staticClass:"col-md-12 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Description")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Description),expression:"currentDataObj.details.Description"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-description","type":"text"},domProps:{"value":(_vm.currentDataObj.details.Description)},on:{"keyup":function($event){return _vm.updateField(
                    'Description', //PropertyName
                    _vm.currentDataObj.details.Description, //Property Value
                    'Description', //Display Name,
                    _vm.currentDataObj.details.Description, //Display Value
                    'description' //Data object Property Name
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "Description", $event.target.value)}}})]),_c('div',{staticClass:"col-md-4 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Model Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ModelNumber),expression:"currentDataObj.details.ModelNumber"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-model-number","type":"text"},domProps:{"value":(_vm.currentDataObj.details.ModelNumber)},on:{"keyup":function($event){return _vm.updateField(
                    'ModelNumber', //PropertyName
                    _vm.currentDataObj.details.ModelNumber, //Property Value
                    'Model Number', //Display Name,
                    _vm.currentDataObj.details.ModelNumber, //Display Value
                    'modelNumber' //Data object Property Name
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "ModelNumber", $event.target.value)}}})]),_c('div',{staticClass:"col-md-4 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("SKU/Lookup Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.SKU),expression:"currentDataObj.details.SKU"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-lookup-code","type":"text"},domProps:{"value":(_vm.currentDataObj.details.SKU)},on:{"keyup":function($event){return _vm.updateField(
                    'SKU', //PropertyName
                    _vm.currentDataObj.details.SKU, //Property Value
                    'SKU/Lookup Code', //Display Name,
                    _vm.currentDataObj.details.SKU, //Display Value
                    'SKU' //Data object Property Name
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "SKU", $event.target.value)}}})]),_c('div',{staticClass:"col-md-4 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Serial Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.SerialNumber),expression:"currentDataObj.details.SerialNumber"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-serial-number","type":"text"},domProps:{"value":(_vm.currentDataObj.details.SerialNumber)},on:{"keyup":function($event){return _vm.updateField(
                    'SerialNumber', //PropertyName
                    _vm.currentDataObj.details.SerialNumber, //Property Value
                    'SerialNumber', //Display Name,
                    _vm.currentDataObj.details.SerialNumber, //Display Value
                    'SerialNumber' //Data object Property Name
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "SerialNumber", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Sale Price")]),_c('div',{staticClass:"input-group input-with-dollar-sign"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.SalePrice),expression:"currentDataObj.details.SalePrice"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-sale-price","type":"text"},domProps:{"value":(_vm.currentDataObj.details.SalePrice)},on:{"keyup":function($event){return _vm.updateField(
                      'SalePrice', //PropertyName
                      _vm.currentDataObj.details.SalePrice, //Property Value
                      'Selling Price', //Display Name,
                      _vm.currentDataObj.details.SalePrice, //Display Value
                      'salePrice' //Data object Property Name
                    )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "SalePrice", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Cost Price")]),_c('div',{staticClass:"input-group input-with-dollar-sign"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ItemCost),expression:"currentDataObj.details.ItemCost"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-cost-price","type":"text"},domProps:{"value":(_vm.currentDataObj.details.ItemCost)},on:{"keyup":function($event){return _vm.updateField(
                      'ItemCost', //PropertyName
                      _vm.currentDataObj.details.ItemCost, //Property Value
                      'Cost', //Display Name,
                      _vm.currentDataObj.details.ItemCost, //Display Value
                      'itemCost' //Data object Property Name
                    )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "ItemCost", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Quantity")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Qty),expression:"currentDataObj.details.Qty"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-quantity","type":"text"},domProps:{"value":(_vm.currentDataObj.details.Qty)},on:{"keyup":function($event){return _vm.updateField(
                    'Qty', //PropertyName
                    _vm.currentDataObj.details.Qty, //Property Value
                    'Quantity', //Display Name,
                    _vm.currentDataObj.details.Qty, //Display Value
                    'Qty' //Data object Property Name
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "Qty", $event.target.value)}}})])])])])],1),_c('div',{staticClass:"col-lg-6 col-md-12 pl-lg-1"},[_c('b-card',{staticClass:"details"},[_c('div',{staticClass:"header mb-3 d-flex justify-content-between"},[_c('h5',[_vm._v("Stock Information")]),_c('i',{staticClass:"fa fa-info-circle info-icon text-secondary",attrs:{"id":"pcontact"}},[_c('b-tooltip',{attrs:{"target":"pcontact","triggers":"hover","placement":"righttop"}},[_c('div',[_vm._v("Stock Information")]),_c('p',[_vm._v("Enter the quantity on hand etc.")])])],1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Quantity on Hand")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.QuantitityOnHand),expression:"currentDataObj.details.QuantitityOnHand"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-quantity-on-hand","type":"text"},domProps:{"value":(_vm.currentDataObj.details.QuantitityOnHand)},on:{"keyup":function($event){return _vm.updateField(
                    'QuantitityOnHand', //PropertyName
                    _vm.currentDataObj.details.QuantitityOnHand, //Property Value
                    'Qty on Hand', //Display Name,
                    _vm.currentDataObj.details.QuantitityOnHand, //Display Value
                    'quantitityOnHand' //Data object Property Name
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "QuantitityOnHand", $event.target.value)}}})]),_c('div',{staticClass:"col-md-4 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Re-order Point")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ReorderPoint),expression:"currentDataObj.details.ReorderPoint"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-reorder-point","type":"text"},domProps:{"value":(_vm.currentDataObj.details.ReorderPoint)},on:{"keyup":function($event){return _vm.updateField(
                    'ReorderPoint', //PropertyName
                    _vm.currentDataObj.details.ReorderPoint, //Property Value
                    'Reorder Point', //Display Name,
                    _vm.currentDataObj.details.ReorderPoint, //Display Value
                    'reorderPoint' //Data object Property Name
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "ReorderPoint", $event.target.value)}}})]),_c('div',{staticClass:"col-md-4 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Re-order Qty")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ReorderQuantity),expression:"currentDataObj.details.ReorderQuantity"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-invetory-reorder-qty","type":"text"},domProps:{"value":(_vm.currentDataObj.details.ReorderQuantity)},on:{"keyup":function($event){return _vm.updateField(
                    'ReorderQuantity', //PropertyName
                    _vm.currentDataObj.details.ReorderQuantity, //Property Value
                    'Reorder Qty', //Display Name,
                    _vm.currentDataObj.details.ReorderQuantity, //Display Value
                    'reorderQuantity' //Data object Property Name
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentDataObj.details, "ReorderQuantity", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-inventory-category","label":"Category","actionId":_vm.MODULE.INVENTORY.UX.InventoryCategory}}),_c('v-select',{attrs:{"options":_vm.dropdowns.uxCategory,"id":"slidepanel-edit-invetory-category","disabled":_vm.isDisabled,"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.updateField(
                    'IntInventoryCategoryID', //PropertyName
                    _vm.dropdowns.selectedCategory.id, //Property Value
                    'Category', //Display Name,
                    _vm.dropdowns.selectedCategory.id, //Display Value
                    'IntInventoryCategoryID' //Data object Property Name
                  )}},model:{value:(_vm.dropdowns.selectedCategory),callback:function ($$v) {_vm.$set(_vm.dropdowns, "selectedCategory", $$v)},expression:"dropdowns.selectedCategory"}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-inventory-unit-type","label":"Unit Type","actionId":_vm.MODULE.INVENTORY.UX.InventoryUnitType}}),_c('v-select',{attrs:{"options":_vm.dropdowns.uxUnitType,"id":"slidepanel-edit-invetory-unit-type","disabled":_vm.isDisabled,"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.updateField(
                    'IntUnitMeasurementID ', //PropertyName
                    _vm.dropdowns.selectedUnitType.id, //Property Value
                    'Unit Type', //Display Name,
                    _vm.dropdowns.selectedUnitType.id, //Display Value
                    'IntUnitMeasurementID ' //Data object Property Name
                  )}},model:{value:(_vm.dropdowns.selectedUnitType),callback:function ($$v) {_vm.$set(_vm.dropdowns, "selectedUnitType", $$v)},expression:"dropdowns.selectedUnitType"}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-inventory-bin-location","label":"Bin Location","actionId":_vm.MODULE.INVENTORY.UX.InventoryLocation}}),_c('v-select',{attrs:{"options":_vm.dropdowns.uxBinLocation,"id":"slidepanel-edit-invetory-bin-location","disabled":_vm.isDisabled,"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.updateField(
                    'IntInventoryLocationID', //PropertyName
                    _vm.dropdowns.selectedBinLocation.id, //Property Value
                    'Bin Location', //Display Name,
                    _vm.dropdowns.selectedBinLocation.id, //Display Value
                    'IntInventoryLocationID' //Data object Property Name
                  )}},model:{value:(_vm.dropdowns.selectedBinLocation),callback:function ($$v) {_vm.$set(_vm.dropdowns, "selectedBinLocation", $$v)},expression:"dropdowns.selectedBinLocation"}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-inventory-warehouse","label":"Warehouse","actionId":_vm.MODULE.INVENTORY.UX.InventoryWarehouse}}),_c('v-select',{attrs:{"options":_vm.dropdowns.uxWarehouse,"id":"slidepanel-edit-invetory-warehouse","disabled":_vm.isDisabled,"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.updateField(
                    'IntInventoryWarehouseID', //PropertyName
                    _vm.dropdowns.selectedWarehouse.id, //Property Value
                    'Warehouse', //Display Name,
                    _vm.dropdowns.selectedWarehouse.id, //Display Value
                    'IntInventoryWarehouseID' //Data object Property Name
                  )}},model:{value:(_vm.dropdowns.selectedWarehouse),callback:function ($$v) {_vm.$set(_vm.dropdowns, "selectedWarehouse", $$v)},expression:"dropdowns.selectedWarehouse"}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Primary Supplier")]),_c('v-select',{attrs:{"options":_vm.dropdowns.uxSupplier,"id":"slidepanel-edit-invetory-primary-supplier","disabled":_vm.isDisabled,"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.updateField(
                    'IntPrimarySupplierID', //PropertyName
                    _vm.dropdowns.selectedSupplier1.id, //Property Value
                    'Primary Supplier', //Display Name,
                    _vm.dropdowns.selectedSupplier1.id, //Display Value
                    'IntPrimarySupplierID' //Data object Property Name
                  )}},model:{value:(_vm.dropdowns.selectedSupplier1),callback:function ($$v) {_vm.$set(_vm.dropdowns, "selectedSupplier1", $$v)},expression:"dropdowns.selectedSupplier1"}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Secondary Supplier")]),_c('v-select',{attrs:{"options":_vm.dropdowns.uxSupplier,"id":"slidepanel-edit-invetory-secondary-supplier","disabled":_vm.isDisabled,"clearable":false,"label":"displayName"},on:{"input":function($event){return _vm.updateField(
                    'IntSecondarySupplierID', //PropertyName
                    _vm.dropdowns.selectedSupplier2.id, //Property Value
                    'Secondary Supplier', //Display Name,
                    _vm.dropdowns.selectedSupplier2.id, //Display Value
                    'IntSecondarySupplierID' //Data object Property Name
                  )}},model:{value:(_vm.dropdowns.selectedSupplier2),callback:function ($$v) {_vm.$set(_vm.dropdowns, "selectedSupplier2", $$v)},expression:"dropdowns.selectedSupplier2"}})],1)])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }